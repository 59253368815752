import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses, ToastWarn } from "../../Utils/Tost";
import { useLocation, useNavigate } from "react-router-dom";

import { axiosInstance } from "../../config";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
const CreateInsuranceCompany = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { value, Mode, view } = state || {};
  const [Name, setName] = useState(value?.Name || "");
  const [AliasName, setAliasName] = useState(value?.AliasName || "");
  const [CompanyBranchData, setCompanyBranchData] = useState(
    value?.CompanyBranch || []
  );
  const [Email, setEmail] = useState(value?.Email || "");
  const [Address, setAddress] = useState(value?.Address || "");
  const [Number, setNumber] = useState(value?.Number || "");
  const [CompanyLogo, setCompanyLogo] = useState();
  const [branchInput, setBranchInput] = useState("");
  const [isEdit, setIsEdit] = useState(null);
  const [formData, setFormData] = useState({
    policyNumberLengths: value?.policyNumberLengths || "",
    typeOfValue: value?.typeOfValue || "",
    payoutOn: value?.payoutOn || "IssueDate",
    alternatePolicy: value?.alternatePolicy || "No",
    policyInputPlaceholder: value?.policyInputPlaceholder || "",
    roundOn: value?.roundOn || "Round",
  });

  const addBranch = async () => {
    if (!branchInput.trim()) {
      ToastWarn("Branch name cannot be empty");
      return;
    }

    try {
      const {
        data: { success, message, data },
      } = await axiosInstance.post("/InsuranceCompany/CompanyBranch", {
        BranchName: branchInput.trim(),
      });

      if (success) {
        setCompanyBranchData((prevState) => [...prevState, data]);

        setBranchInput("");
        setIsEdit(null);
        ToastSusses(message); // Show success message
      } else {
        ToastError(message); // Show error message if any
      }
    } catch (error) {
      console.error("Error adding branch:", error);
      ToastError("Failed to add branch");
    }
  };

  const removeBranch = async (index) => {
    try {
      const branchId = index;
      const isConfirmed = window.confirm(
        "Are you sure you want to delete this branch?"
      );

      if (!isConfirmed) {
        return; // If the user cancels, exit the function
      }
      const {
        data: { success, message },
      } = await axiosInstance.delete(
        `/InsuranceCompany/CompanyBranch/deleteCompanyBranch?id=${branchId}`
      );

      if (success) {
        // Update the state to remove the branch from the list
        const updatedBranches = CompanyBranchData.filter(
          (branch) => branch._id !== branchId
        );
        setCompanyBranchData(updatedBranches);
        ToastSusses(message); // Display success toast
      } else {
        ToastError(message); // Display error toast if success is false
      }
    } catch (error) {
      console.error("Error deleting branch:", error);
      ToastError("Failed to delete branch"); // Display error toast for API failure
    }
  };

  const saveEditBranch = async (id) => {
    try {
      const {
        data: { success, message, data: updatedBranch },
      } = await axiosInstance.put(`/InsuranceCompany/CompanyBranch/${id}`, {
        BranchName: branchInput.trim(),
      });

      if (success) {
        setIsEdit(null);
        setBranchInput("");
        setCompanyBranchData((prevState) => [...prevState, updatedBranch]);
        ToastSusses(message);
      }
    } catch (error) {
      console.error("Error saving branch:", error);
      ToastError("Failed to save branch");
    }
  };

  const handleCreate = async () => {
    try {
      if (!Name) {
        ToastWarn("Please Name is required");
      }
      const companyids =
        CompanyBranchData.map((branch) => [branch._id]) || null;

      const EmpData = new FormData();
      EmpData.append("Name", Name);
      EmpData.append("AliasName", AliasName);
      EmpData.append("Email", Email);
      EmpData.append("Address", Address);
      EmpData.append("Number", Number);
      EmpData.append("CompanyLogo", CompanyLogo);
      EmpData.append("policyNumberLengths", formData.policyNumberLengths);
      EmpData.append("typeOfValue", formData.typeOfValue);
      EmpData.append("payoutOn", formData.payoutOn);
      EmpData.append("alternatePolicy", formData.alternatePolicy);
      EmpData.append("policyInputPlaceholder", formData.policyInputPlaceholder);
      EmpData.append("roundOn", formData.roundOn);
      if (companyids !== null) {
        EmpData.append("companyids", JSON.stringify(companyids));
      }

      const {
        data: { success, message },
      } = await axiosInstance.post("/InsuranceCompany", EmpData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/InsuranceCompany/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (value?._id) {
        const id = value._id;

        try {
          const {
            data: { success, message, data },
          } = await axiosInstance.post(
            "/InsuranceCompany/CompanyBranch/getallcompanyBranch",
            { id }
          );

          if (success) {
            setCompanyBranchData(data);
          } else {
            console.log(message);
          }
        } catch (error) {
          console.error("Error fetching company branches:", error);
        }
      }
    };

    fetchData();
  }, [value]);

  const handleEdit = async () => {
    try {
      const companyids =
        CompanyBranchData.map((branch) => [branch._id]) || null;

      const EmpData = new FormData();
      EmpData.append("Name", Name);
      EmpData.append("AliasName", AliasName);
      EmpData.append("Email", Email);
      EmpData.append("Address", Address);
      EmpData.append("Number", Number);
      EmpData.append("CompanyLogo", CompanyLogo);
      EmpData.append("policyNumberLengths", formData.policyNumberLengths);
      EmpData.append("typeOfValue", formData.typeOfValue);
      EmpData.append("payoutOn", formData.payoutOn);
      EmpData.append("alternatePolicy", formData.alternatePolicy);
      EmpData.append("policyInputPlaceholder", formData.policyInputPlaceholder);
      EmpData.append("roundOn", formData.roundOn);
      if (companyids !== null) {
        EmpData.append("companyids", JSON.stringify(companyids));
      }
      const {
        data: { success, message },
      } = await axiosInstance.put(`/InsuranceCompany/${value?._id}`, EmpData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/InsuranceCompany/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const handleEditClick = (data) => {
    setIsEdit(data?._id);
    setBranchInput(data?.BranchName);
    setCompanyBranchData((prevState) =>
      prevState.filter((branch) => branch._id !== data._id)
    );
  };

  return (
    <>
      <Header />
      <div className="MainDiv MainDivPadding">
        <div className="container-fluid">
          <div className="row CreateCard p-3">
            <div className="">
              <h4 className="card-title">
                {view
                  ? "View  Insurance Company"
                  : Mode
                  ? "Edit Insurance Company"
                  : "Create Insurance Company"}
              </h4>
              <hr />

              <div data-repeater-item="" className="outer">
                <div
                  className="col-md-4 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label required>Name</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="text"
                    name="Name"
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter Insurance Company Name"
                    required
                  />
                </div>
                <div
                  className="col-md-4 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label required>Alias Name</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="text"
                    name="Alias"
                    value={AliasName}
                    onChange={(e) => setAliasName(e.target.value)}
                    placeholder="Enter Insurance Company Alias Name"
                    required
                  />
                </div>

                <div
                  className="col-md-4 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label>Email Id</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="text"
                    name="Email"
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="xyz@gmail.com"
                    //done
                  />
                </div>
                <div
                  className="col-md-4 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label>Number</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="text"
                    name="Number"
                    value={Number}
                    onChange={(e) => setNumber(e.target.value)}
                    placeholder="77xxxxxxxx"
                    //done
                  />
                </div>
                <div
                  className="col-md-4 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label>Address</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="text"
                    name="Address"
                    value={Address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Address"
                    //done
                  />
                </div>
                <div
                  className="col-md-4 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label>Policy Number Lengths (comma-separated)</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="text"
                    name="PolicyNumberLengths"
                    value={formData.policyNumberLengths}
                    onChange={(e) => {
                      const value = e.target.value;
                      const regex = /^(\d+,)*\d*,?$/;
                      if (regex.test(value) || value === "") {
                        setFormData({
                          ...formData,
                          policyNumberLengths: value,
                        });
                      } else {
                        ToastWarn(
                          "Please enter numbers separated by commas only"
                        );
                      }
                    }}
                    placeholder="Enter policy number lengths"
                    //done
                  />
                </div>
                <div
                  className="col-md-4 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label htmlFor="select-filed">Type of value</label>
                  <select
                    id="select-filed"
                    disabled={view}
                    className="form-control"
                    value={formData.typeOfValue}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        typeOfValue: e.target.value,
                      }))
                    }
                  >
                    <option value="">----- Select type of value -----</option>
                    <option value="Numeric">Numeric</option>
                    <option value="Alphanumeric">Alphanumeric</option>
                  </select>
                </div>
                <div
                  className="col-md-4 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label>Policy Input Placeholder</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="text"
                    name="policyInputPlaceholder"
                    value={formData.policyInputPlaceholder}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        policyInputPlaceholder: e.target.value,
                      }))
                    }
                    placeholder="Enter policy input placeholder"
                    //done
                  />
                </div>
                <div
                  className="col-md-4 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label>Logo</label>
                  <input
                    disabled={view}
                    className="form-control"
                    type="file"
                    name="CompanyLogo"
                    // value={CompanyLogo}
                    onChange={(e) => setCompanyLogo(e.target.files[0])}
                    placeholder="CompanyLogo"
                    //done
                  />
                </div>

                <div
                  className="col-md-4 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label htmlFor="select-filed">Payout Calculate On</label>
                  <select
                    id="select-filed"
                    disabled={view}
                    className="form-control"
                    value={formData.payoutOn}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        payoutOn: e.target.value,
                      }))
                    }
                  >
                    <option value="IssueDate">Issue Date</option>
                    <option value="RSD">Start Date (RSD)</option>
                  </select>
                </div>

                <div
                  className="col-md-4 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label htmlFor="select-filed">Alternate Policy Number</label>
                  <select
                    id="select-filed"
                    disabled={view}
                    className="form-control"
                    value={formData.alternatePolicy}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        alternatePolicy: e.target.value,
                      }))
                    }
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </div>

                <div
                  className="col-md-4 align-items-center"
                  style={{ float: "left", padding: 15 }}
                >
                  <label htmlFor="select-filed">Age Calculate On</label>
                  <select
                    id="select-filed"
                    disabled={view}
                    className="form-control"
                    value={formData.roundOn}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        roundOn: e.target.value,
                      }))
                    }
                  >
                    <option value="Exact">Exact</option>
                    <option value="Round">Round</option>
                  </select>
                </div>
                <div data-repeater-item="" className="outer">
                  <div className="col-md-12 mt-4">
                    <h5>Company Branches</h5>
                    <div className="d-flex align-items-center mb-3">
                      <input
                        disabled={view}
                        type="text"
                        className="form-control"
                        value={branchInput}
                        onChange={(e) => setBranchInput(e.target.value)}
                        placeholder="Enter branch name"
                      />
                      <button
                        className="btn btn-primary ml-3"
                        onClick={() =>
                          isEdit ? saveEditBranch(isEdit) : addBranch()
                        }
                        disabled={view}
                      >
                        {isEdit ? "Edit" : "Add"}
                      </button>
                    </div>
                    <ul className="list-group col-md-4 w-50">
                      {CompanyBranchData.map((branch) => (
                        <li
                          key={branch?._id}
                          className="list-group-item d-flex justify-content-between align-items-center"
                        >
                          <>
                            <span className="mr-auto">{branch.BranchName}</span>
                            <div className="ml-auto d-flex">
                              <button
                                className="btn btn-primary ml-2"
                                onClick={() => handleEditClick(branch)}
                                disabled={view}
                              >
                                Edit
                              </button>
                              <button
                                className="btn btn-danger ml-2 "
                                onClick={() => removeBranch(branch._id)}
                                disabled={view}
                              >
                                Delete
                              </button>
                            </div>
                          </>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex gap-3 ">
            <button
              type="submit"
              className="btn mybtn"
              // style={{ marginTop: 28 }}
              onClick={() => navigate("/admin/masters/InsuranceCompany/data")}
            >
              Back
            </button>
            {!view && (
              <button
                type="submit"
                className="btn mybtn"
                // style={{ marginTop: 28 }}
                onClick={() => {
                  Mode ? handleEdit() : handleCreate();
                }}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreateInsuranceCompany;
