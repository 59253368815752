import React, { useEffect, useState } from "react";
// import { ToastError, ToastSusses } from "../../Utils/Tost";
import { Link, useNavigate, useParams } from "react-router-dom";
import { REACT_APP_FileURL, axiosInstance } from "../../config";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import {
  reversDate,
  returnAgeDifference,
  ReturnFullName,
} from "../../Utils/usefullFunction";

const ViewPolicy = () => {
  const { PolicyId } = useParams();

  const [Policy, setPolicy] = useState({});
  const [IsNonMotorPolicy, setIsNonMotorPolicy] = useState(false);
  const [count, setCount] = useState(0);
  const [PolicyMetaData, setPolicyMetaData] = useState([
    {
      viewName: "Policy Copy",
      url: "",
      Name: "NewPolicyCopy",
    },
    {
      viewName: "First RC",
      url: "",
      Name: "RC1",
    },
    {
      viewName: "Second RC",
      url: "",
      Name: "RC2",
    },
    {
      viewName: "First Previous Policy",
      url: "",
      Name: "PreviousPolicy1",
    },
    {
      viewName: "Second Previous Policy",
      url: "",
      Name: "PreviousPolicy2",
    },
  ]);
  const navigate = useNavigate();

  const getSingle = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(`/Policy/viewPolicy/${PolicyId}`);

      setPolicy({
        ...data,
      });
      setIsNonMotorPolicy(data?.IsNonMotorPolicy ?? false);
      setPolicyMetaData([
        {
          viewName: "Policy Copy",
          url: data?.NewPolicyCopy ?? "",
          Name: "NewPolicyCopy",
        },
        {
          viewName: "First RC",
          url: data?.RC1 ?? "",
          Name: "RC1",
        },
        {
          viewName: "Second RC",
          url: data?.RC2 ?? "",
          Name: "RC2",
        },
        {
          viewName: "First Previous Policy",
          url: data?.PreviousPolicy1 ?? "",
          Name: "PreviousPolicy1",
        },
        {
          viewName: "Second Previous Policy",
          url: data?.PreviousPolicy2 ?? "",
          Name: "PreviousPolicy2",
        },
      ]);
    } catch (error) {}
  };
  useEffect(() => {
    getSingle();
  }, []);

  return (
    <>
      <Header />
      <div
        className="container-fluid"
        style={{ marginTop: "70px", zoom: "80%" }}
      >
        <div className="row">
          <PolicyDetails
            Policy={Policy}
            IsNonMotorPolicy={IsNonMotorPolicy}
            PolicyMetaData={PolicyMetaData}
            count={count}
            setCount={setCount}
          />
          <div
            className="d-flex justify-content-center gap-3 mb-2"
            style={{ marginTop: "20px" }}
          >
            <button
              className="btn mybtn"
              // style={{ marginTop: 28 }}
              onClick={() => navigate("/admin/Policy/data")}
            >
              Back
            </button>
            <Link
              className="btn mybtn"
              state={{
                value: {
                  ...Policy,
                  Broker: Policy?.Broker?._id,
                  InsuranceCompany: Policy?.InsuranceCompany?._id,
                  InsuranceType: Policy?.InsuranceType?._id,
                  MakeModal: Policy?.MakeModal?._id,
                  POS: Policy?.POS?._id,
                  PolicyType: Policy?.PolicyType?._id,
                },
                Mode: true,
              }}
              to={`/admin/Policy/${Policy?._id}`}
            >
              Edit
            </Link>
          </div>
        </div>
      </div>{" "}
      <Footer />
    </>
  );
};

const PolicyDetails = ({
  Policy: value,
  IsNonMotorPolicy,
  PolicyMetaData,
  count,
  setCount,
}) => {
  return (
    <div className="row mx-2 ">
      <div
        className="col-md-7 align-items-center "
        style={{
          height: "100vh",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "1rem 0rem",
            width: "100%",
          }}
        >
          <button
            className="btn"
            style={{
              backgroundColor: "#E8960F",
              borderRadius: "7px",
              color: "white",
            }}
            onClick={() => {
              setCount((p) => {
                if (p === 0) return 4;
                return p - 1;
              });
            }}
          >
            Previous
          </button>

          <h5>{PolicyMetaData[count]?.viewName ?? ""}</h5>

          <button
            className="btn"
            style={{
              backgroundColor: "green",
              borderRadius: "7px",
              color: "white",
            }}
            onClick={() => {
              setCount((p) => {
                if (p === 4) return 0;
                return p + 1;
              });
            }}
          >
            Next
          </button>
        </div>
        {PolicyMetaData[count]?.url ? (
          <embed
            src={`${REACT_APP_FileURL}${PolicyMetaData[count].url}#zoom=67`}
            type="application/pdf"
            width="100%"
            height="90%"
          />
        ) : (
          <>
            <h5> Not Uploaded</h5>
          </>
        )}
      </div>
      <div className="col-md-5 align-items-center">
        <div
          class=""
          style={{
            display: "flex",
            // justifyContent: "center",
            marginTop: "4rem",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {/* <div class="col-12" style={{ marginBottom: "9px" }}></div> */}
          <div class="col-lg-4" style={{ float: "left" }}>
            <div class="col-lg-11" style={{ float: "left" }}>
              <label>Policy No.</label>
              <div>
                <input
                  value={value?.PolicyNumber ?? ""}
                  disabled
                  className="form-control"
                ></input>
                {/* <b> {value?.PolicyNumber ?? ""} </b> */}
              </div>
            </div>
          </div>

          <div class="col-lg-4" style={{ float: "left" }}>
            <div class="col-lg-11" style={{ float: "left" }}>
              <label>Control No.</label>
              <div>
                <input
                  value={value?.ControlNo ?? ""}
                  disabled
                  className="form-control"
                ></input>
                {/* <b> {value?.PolicyNumber ?? ""} </b> */}
              </div>
            </div>
          </div>

          <div class="col-lg-4" style={{ float: "left" }}>
            <div class="col-lg-11" style={{ float: "left" }}>
              <label>Insured Name</label>
              <div>
                <input
                  value={value?.InsureeName ?? ""}
                  disabled
                  className="form-control"
                ></input>
                {/* <b> {value?.InsureeName ?? ""} </b> */}
              </div>
            </div>
          </div>
          <div class="col-lg-4" style={{ float: "left" }}>
            <div class="col-lg-11" style={{ float: "left" }}>
              <label>
                Issue Date{" "}
                <span
                  style={{ color: "red" }}
                  id="Vali"
                  data-toggle="popover"
                  title="Format: UP15AA1234"
                ></span>{" "}
              </label>
              <div>
                <input
                  value={value?.IssueDate?.split("-").reverse().join("-")}
                  disabled
                  className="form-control"
                />
                {/* <b>{value?.IMTType}</b> */}
              </div>
            </div>
          </div>
          {!IsNonMotorPolicy && (
            <div class="col-lg-4" style={{ float: "left" }}>
              <div class="col-lg-11" style={{ float: "left" }}>
                <label>Vehicle No.</label>
                <div>
                  <input
                    value={value?.VehicleNumber ?? ""}
                    disabled
                    className="form-control"
                  ></input>
                  {/* <b> {value?.VehicleNumber ?? ""} </b> */}
                </div>
              </div>
            </div>
          )}
          <div class="col-lg-4" style={{ float: "left" }}>
            <div class="col-lg-11" style={{ float: "left" }}>
              <label>RSD</label>
              <div>
                {/* <b> {value?.RSD ?? ""} </b> */}
                <input
                  value={reversDate(value?.RSD) ?? ""}
                  disabled
                  className="form-control"
                ></input>
              </div>
            </div>
          </div>
          <div class="col-lg-4" style={{ float: "left" }}>
            <div class="col-lg-11" style={{ float: "left" }}>
              <label>RED</label>
              <div>
                {/* <b> {value?.RSD ?? ""} </b> */}
                <input
                  value={reversDate(value?.RED) ?? ""}
                  disabled
                  className="form-control"
                ></input>
              </div>
            </div>
          </div>
          {!IsNonMotorPolicy && (
            <div class="col-lg-4" style={{ float: "left" }}>
              <div class="col-lg-11" style={{ float: "left" }}>
                Year Of Manufacture
                <div>
                  <input
                    value={value?.YearOfManufacture ?? ""}
                    disabled
                    className="form-control"
                  ></input>
                  {/* <b> {value?.YearOfManufacture ?? ""} </b> */}
                </div>
              </div>
            </div>
          )}
          <div class="col-lg-4" style={{ float: "left" }}>
            <div class="col-lg-11" style={{ float: "left" }}>
              <label>
                Product{" "}
                <span
                  style={{ color: "red" }}
                  id="Vali"
                  data-toggle="popover"
                  title="Format: UP15AA1234"
                ></span>{" "}
              </label>
              <div>
                <input
                  value={value?.InsuranceType?.InsuranceType ?? ""}
                  disabled
                  className="form-control"
                ></input>
              </div>
            </div>
          </div>

          {!IsNonMotorPolicy && (
            <div class="col-lg-4" style={{ float: "left" }}>
              <div class="col-lg-11" style={{ float: "left" }}>
                <label>
                  Insurer{" "}
                  <span
                    style={{ color: "red" }}
                    id="Vali"
                    data-toggle="popover"
                    title="Format: UP15AA1234"
                  ></span>{" "}
                </label>
                <div>
                  <input
                    value={value?.InsuranceCompany?.AliasName ?? ""}
                    disabled
                    className="form-control"
                  ></input>
                </div>
              </div>
            </div>
          )}

          <div class="col-lg-4" style={{ float: "left" }}>
            <div class="col-lg-11" style={{ float: "left" }}>
              <label>
                Branch{" "}
                <span
                  style={{ color: "red" }}
                  id="Vali"
                  data-toggle="popover"
                  title="Format: UP15AA1234"
                ></span>{" "}
              </label>
              <div>
                <input
                  value={value?.Branch?.BranchName ?? ""}
                  disabled
                  className="form-control"
                ></input>
              </div>
            </div>
          </div>

          {!IsNonMotorPolicy && (
            <div class="col-lg-4" style={{ float: "left" }}>
              <div class="col-lg-11" style={{ float: "left" }}>
                <label>
                  Model{" "}
                  <span
                    style={{ color: "red" }}
                    id="Vali"
                    data-toggle="popover"
                    title="Format: UP15AA1234"
                  ></span>{" "}
                </label>
                <div>
                  <input
                    value={value?.Model?.Model ?? ""}
                    disabled
                    className="form-control"
                  ></input>
                </div>
              </div>
            </div>
          )}

          {!IsNonMotorPolicy && (
            <div class="col-lg-4" style={{ float: "left" }}>
              <div class="col-lg-11" style={{ float: "left" }}>
                <label>
                  Variant{" "}
                  <span
                    style={{ color: "red" }}
                    id="Vali"
                    data-toggle="popover"
                    title="Format: UP15AA1234"
                  ></span>{" "}
                </label>
                <div>
                  <input
                    value={value?.Variant?.VariantName ?? ""}
                    disabled
                    className="form-control"
                  ></input>
                </div>
              </div>
            </div>
          )}

          {!IsNonMotorPolicy && (
            <div class="col-lg-4" style={{ float: "left" }}>
              <div class="col-lg-11" style={{ float: "left" }}>
                <label>
                  Make
                  <span
                    style={{ color: "red" }}
                    id="Vali"
                    data-toggle="popover"
                    title="Format: UP15AA1234"
                  ></span>{" "}
                </label>
                <div>
                  <input
                    value={value?.MakeModal?.Make ?? ""}
                    disabled
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}

          {!IsNonMotorPolicy && (
            <div class="col-lg-4" style={{ float: "left" }}>
              <div class="col-lg-11" style={{ float: "left" }}>
                <label>
                  Policy Type{" "}
                  <span
                    style={{ color: "red" }}
                    id="Vali"
                    data-toggle="popover"
                    title="Format: UP15AA1234"
                  ></span>{" "}
                </label>
                <div>
                  <input
                    value={value?.PolicyType?.PolicyTypeName ?? ""}
                    disabled
                    className="form-control"
                  ></input>
                </div>
              </div>
            </div>
          )}

          {!IsNonMotorPolicy && (
            <div class="col-lg-4" style={{ float: "left" }}>
              <div class="col-lg-11" style={{ float: "left" }}>
                <label>
                  NCB{" "}
                  <span
                    style={{ color: "red" }}
                    id="Vali"
                    data-toggle="popover"
                    title="Format: UP15AA1234"
                  ></span>{" "}
                </label>

                <div>
                  {/* Not Applicable  = -1*/}
                  {/* No  = 0*/}
                  {/* Yes  = 20,25,35,45,50 */}
                  <input
                    value={
                      value?.NCB === 0
                        ? "No"
                        : value?.NCB < 0
                        ? "Not Applicable"
                        : "Yes"
                    }
                    // value={value?.NCB }
                    disabled
                    className="form-control"
                  ></input>
                  {/* <b>{value?.NCB === 0 ? "No" : "Yes"}</b> */}
                  {/* // value?.NCB === 0
                              //   ? "No"
                              //   : value?.NCB > 0
                              //   ? "Yes"
                              //   : value?.NCB < 0 && "Not Applicable" */}
                </div>
              </div>
            </div>
          )}
          {!IsNonMotorPolicy && (
            <div class="col-lg-4" style={{ float: "left" }}>
              <div class="col-lg-11" style={{ float: "left" }}>
                <label>
                  IMT Type{" "}
                  <span
                    style={{ color: "red" }}
                    id="Vali"
                    data-toggle="popover"
                    title="Format: UP15AA1234"
                  ></span>{" "}
                </label>

                <div>
                  <input
                    value={value?.IMTType}
                    disabled
                    className="form-control"
                  ></input>
                  {/* <b>{value?.IMTType}</b> */}
                </div>
              </div>
            </div>
          )}

          <div class="col-lg-4" style={{ float: "left" }}>
            <div class="col-lg-11" style={{ float: "left" }}>
              <label>
                Business Type{" "}
                <span
                  style={{ color: "red" }}
                  id="Vali"
                  data-toggle="popover"
                  title="Format: UP15AA1234"
                ></span>{" "}
              </label>

              <div>
                <input
                  value={value?.BusinessType}
                  disabled
                  className="form-control"
                ></input>
                {/* <b>{value?.IMTType}</b> */}
              </div>
            </div>
          </div>

          {!IsNonMotorPolicy && (
            <div class="col-lg-4" style={{ float: "left" }}>
              <div class="col-lg-11" style={{ float: "left" }}>
                <label>
                  Nil Dep
                  <span
                    style={{ color: "red" }}
                    id="Vali"
                    data-toggle="popover"
                    title="Format: UP15AA1234"
                  ></span>{" "}
                </label>

                <div>
                  <input
                    value={value?.nillDepth}
                    disabled
                    className="form-control"
                  ></input>
                  {/* <b>{value?.IMTType}</b> */}
                </div>
              </div>
            </div>
          )}

          {!IsNonMotorPolicy && (
            <div class="col-lg-4" style={{ float: "left" }}>
              <div class="col-lg-11" style={{ float: "left" }}>
                <label>
                  Fuel Type{" "}
                  <span
                    style={{ color: "red" }}
                    id="Vali"
                    data-toggle="popover"
                    title="Format: UP15AA1234"
                  ></span>{" "}
                </label>

                <div>
                  <input
                    value={value?.FuelType?.FuelType}
                    disabled
                    className="form-control"
                  ></input>
                  {/* <b>{value?.IMTType}</b> */}
                </div>
              </div>
            </div>
          )}

          <div class="col-lg-4" style={{ float: "left" }}>
            <div class="col-lg-11" style={{ float: "left" }}>
              <label>
                POS{" "}
                <span
                  style={{ color: "red" }}
                  id="Vali"
                  data-toggle="popover"
                  title="Format: UP15AA1234"
                ></span>{" "}
              </label>

              <div>
                <input
                  value={ReturnFullName(value?.POS)}
                  disabled
                  className="form-control"
                ></input>
                {/* <b>{value?.POS?.Name ?? ""}</b> */}
              </div>
            </div>
          </div>
          <div class="col-lg-4" style={{ float: "left" }}>
            <div class="col-lg-11" style={{ float: "left" }}>
              <label>
                Agent Code
                <span
                  style={{ color: "red" }}
                  id="Vali"
                  data-toggle="popover"
                  title="Format: UP15AA1234"
                ></span>{" "}
              </label>

              <div>
                <input
                  value={value?.POS?.AliasName}
                  disabled
                  className="form-control"
                ></input>
                {/* <b>{value?.POS?.Name ?? ""}</b> */}
              </div>
            </div>
          </div>
          <div class="col-lg-4" style={{ float: "left" }}>
            <div class="col-lg-11" style={{ float: "left" }}>
              <label>
                Club{" "}
                <span
                  style={{ color: "red" }}
                  id="Vali"
                  data-toggle="popover"
                  title="Format: UP15AA1234"
                ></span>{" "}
              </label>

              <div>
                <input
                  value={value?.POS?.Club?.Name ?? ""}
                  disabled
                  className="form-control"
                ></input>
                {/* <b>{value?.POS?.Club ?? ""}</b> */}
              </div>
            </div>
          </div>
          {!IsNonMotorPolicy && (
            <div class="col-lg-4" style={{ float: "left" }}>
              <div class="col-lg-11" style={{ float: "left" }}>
                <label>
                  Vehicle Age{" "}
                  <span
                    style={{ color: "red" }}
                    id="Vali"
                    data-toggle="popover"
                    title="Format: UP15AA1234"
                  ></span>{" "}
                </label>

                <div>
                  <input
                    value={`${returnAgeDifference(
                      value?.RegistrationDate,
                      value?.RSD,
                      true
                    )} `}
                    disabled
                    className="form-control"
                  ></input>
                  {/* <b>{value?.POS?.Club ?? ""}</b> */}
                </div>
              </div>
            </div>
          )}

          {!IsNonMotorPolicy && (
            <div class="col-lg-4" style={{ float: "left" }}>
              <div class="col-lg-11" style={{ float: "left" }}>
                <label>
                  CC/watt (1KW=1000Watt)
                  <span
                    style={{ color: "red" }}
                    id="Vali"
                    data-toggle="popover"
                    title="Format: UP15AA1234"
                  ></span>{" "}
                </label>

                <div>
                  <input
                    value={value?.Engine ?? ""}
                    disabled
                    className="form-control"
                  />
                  {/* <b>{value?.TPPremium ?? ""}</b> */}
                </div>
              </div>
            </div>
          )}

          {!IsNonMotorPolicy && (
            <div class="col-lg-4" style={{ float: "left" }}>
              <div class="col-lg-11" style={{ float: "left" }}>
                <label>
                  GVW{" "}
                  <span
                    style={{ color: "red" }}
                    id="Vali"
                    data-toggle="popover"
                    title="Format: UP15AA1234"
                  ></span>{" "}
                </label>

                <div>
                  <input
                    value={value?.GVW ?? ""}
                    disabled
                    className="form-control"
                  />
                  {/* <b>{value?.TPPremium ?? ""}</b> */}
                </div>
              </div>
            </div>
          )}

          <div class="col-lg-4" style={{ float: "left" }}>
            <div class="col-lg-11" style={{ float: "left" }}>
              <label>
                IDV/Sum Insured
                <span
                  style={{ color: "red" }}
                  id="IDV/Sum Insured"
                  data-toggle="popover"
                  title="IDV/Sum Insured"
                ></span>{" "}
              </label>

              <div>
                <input
                  value={value?.IDV_Sum ?? ""}
                  disabled
                  className="form-control"
                />
                {/* <b>{value?.TPPremium ?? ""}</b> */}
              </div>
            </div>
          </div>

          <div class="col-lg-4" style={{ float: "left" }}>
            <div class="col-lg-11" style={{ float: "left" }}>
              <label>
                OD Premium{" "}
                <span
                  style={{ color: "red" }}
                  id="Vali"
                  data-toggle="popover"
                  title="Format: UP15AA1234"
                ></span>{" "}
              </label>

              <div>
                <input
                  value={value?.ODPremium ?? ""}
                  disabled
                  className="form-control"
                ></input>
                {/* <b>{value?.ODPremium ?? ""}</b> */}
              </div>
            </div>
          </div>
          {!IsNonMotorPolicy && (
            <div class="col-lg-4" style={{ float: "left" }}>
              <div class="col-lg-11" style={{ float: "left" }}>
                <label>
                  TP Premium{" "}
                  <span
                    style={{ color: "red" }}
                    id="Vali"
                    data-toggle="popover"
                    title="Format: UP15AA1234"
                  ></span>{" "}
                </label>

                <div>
                  <input
                    value={value?.TPPremium ?? ""}
                    disabled
                    className="form-control"
                  ></input>
                  {/* <b>{value?.TPPremium ?? ""}</b> */}
                </div>
              </div>
            </div>
          )}
          {IsNonMotorPolicy && (
            <div class="col-lg-4" style={{ float: "left" }}>
              <div class="col-lg-11" style={{ float: "left" }}>
                <label>
                  Terrorism Premium{" "}
                  <span
                    style={{ color: "red" }}
                    id="Vali"
                    data-toggle="popover"
                    title="Format: UP15AA1234"
                  ></span>{" "}
                </label>

                <div>
                  <input
                    value={value?.TerrorismPremium ?? ""}
                    disabled
                    className="form-control"
                  ></input>
                  {/* <b>{value?.TPPremium ?? ""}</b> */}
                </div>
              </div>
            </div>
          )}
          {!IsNonMotorPolicy && (
            <div class="col-lg-4" style={{ float: "left" }}>
              <div class="col-lg-11" style={{ float: "left" }}>
                <label>
                  LL Premium{" "}
                  <span
                    style={{ color: "red" }}
                    id="Vali"
                    data-toggle="popover"
                    title="Format: UP15AA1234"
                  ></span>{" "}
                </label>

                <div>
                  <input
                    value={value?.LLPremium ?? ""}
                    disabled
                    className="form-control"
                  ></input>
                  {/* <b>{value?.TPPremium ?? ""}</b> */}
                </div>
              </div>
            </div>
          )}
          {!IsNonMotorPolicy && (
            <div class="col-lg-4" style={{ float: "left" }}>
              <div class="col-lg-11" style={{ float: "left" }}>
                <label>
                  PA Premium{" "}
                  <span
                    style={{ color: "red" }}
                    id="Vali"
                    data-toggle="popover"
                    title="Format: UP15AA1234"
                  ></span>{" "}
                </label>

                <div>
                  <input
                    value={value?.PAPremium ?? ""}
                    disabled
                    className="form-control"
                  ></input>
                  {/* <b>{value?.TPPremium ?? ""}</b> */}
                </div>
              </div>
            </div>
          )}

          {!IsNonMotorPolicy && (
            <div class="col-lg-4" style={{ float: "left" }}>
              <div class="col-lg-11" style={{ float: "left" }}>
                <label>
                  Commissionable Premium{" "}
                  <span
                    style={{ color: "red" }}
                    id="Vali"
                    data-toggle="popover"
                    title="Format: UP15AA1234"
                  ></span>{" "}
                </label>

                <div>
                  <input
                    value={value?.CommissionablePremium ?? ""}
                    disabled
                    className="form-control"
                  ></input>
                  {/* <b>{value?.TPPremium ?? ""}</b> */}
                </div>
              </div>
            </div>
          )}

          {!IsNonMotorPolicy && (
            <div class="col-lg-4" style={{ float: "left" }}>
              <div class="col-lg-11" style={{ float: "left" }}>
                <label>
                  Add On Premium{" "}
                  <span
                    style={{ color: "red" }}
                    id="Vali"
                    data-toggle="popover"
                    title="Format: UP15AA1234"
                  ></span>{" "}
                </label>

                <div>
                  <input
                    value={value?.AddOnPremium ?? ""}
                    disabled
                    className="form-control"
                  ></input>
                  {/* <b>{value?.TPPremium ?? ""}</b> */}
                </div>
              </div>
            </div>
          )}

          {IsNonMotorPolicy && (
            <div class="col-lg-4" style={{ float: "left" }}>
              <div class="col-lg-11" style={{ float: "left" }}>
                <label>
                  LL/Terrorism Premium{" "}
                  <span
                    style={{ color: "red" }}
                    id="Vali"
                    data-toggle="popover"
                    title="Format: UP15AA1234"
                  ></span>{" "}
                </label>

                <div>
                  <input
                    value={value?.LLPremium ?? ""}
                    disabled
                    className="form-control"
                  ></input>
                  {/* <b>{value?.TPPremium ?? ""}</b> */}
                </div>
              </div>
            </div>
          )}

          {IsNonMotorPolicy && (
            <div className="col-lg-4" style={{ float: "left" }}>
              <div className="col-lg-11" style={{ float: "left" }}>
                <label>
                  Entered By{" "}
                  <span
                    style={{ color: "red" }}
                    id="Vali"
                    data-toggle="popover"
                    title="Entered By Information"
                  ></span>
                </label>

                <div>
                  <input
                    value={`${value?.EnteredBy?.Name ?? ""} ${
                      value?.EnteredBy?.MiddleName
                        ? value.EnteredBy.MiddleName + " "
                        : ""
                    }${value?.EnteredBy?.LastName ?? ""}`}
                    disabled
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}

          <div class="col-lg-4" style={{ float: "left" }}>
            <div class="col-lg-11" style={{ float: "left" }}>
              <label>
                ODD %{" "}
                <span
                  style={{ color: "red" }}
                  id="Vali"
                  data-toggle="popover"
                  title="Format: UP15AA1234"
                ></span>{" "}
              </label>

              <div>
                <input
                  value={value?.ODDiscount ?? ""}
                  disabled
                  className="form-control"
                ></input>
                {/* <b>{value?.TPPremium ?? ""}</b> */}
              </div>
            </div>
          </div>

          <div class="col-lg-4" style={{ float: "left" }}>
            <div class="col-lg-11" style={{ float: "left" }}>
              <label>
                NET Premium{" "}
                <span
                  style={{ color: "red" }}
                  id="Vali"
                  data-toggle="popover"
                  title="Format: UP15AA1234"
                ></span>{" "}
              </label>

              <div>
                <input
                  value={value?.NETPremium ?? ""}
                  disabled
                  className="form-control"
                ></input>
                {/* <b>{value?.NETPremium ?? ""}</b> */}
              </div>
            </div>
          </div>
          <div class="col-lg-4" style={{ float: "left" }}>
            <div class="col-lg-11" style={{ float: "left" }}>
              <label>
                Gross Premium{" "}
                <span
                  style={{ color: "red" }}
                  id="Vali"
                  data-toggle="popover"
                  title="Format: UP15AA1234"
                ></span>{" "}
              </label>

              <div>
                <input
                  value={value?.GrossPremium ?? ""}
                  disabled
                  className="form-control"
                ></input>
                {/* <b>{value?.GrossPremium ?? ""}</b> */}
              </div>
            </div>
          </div>
          <div class="col-lg-4" style={{ float: "left" }}>
            <div class="col-lg-11" style={{ float: "left" }}>
              <label>Based On </label>
              <div>
                <input
                  value={value?.BasedOn || ""}
                  disabled
                  className="form-control"
                />
                {/* <b> {BasedOn}</b> */}
              </div>
            </div>
          </div>
          <div class="col-lg-4" style={{ float: "left" }}>
            <div class="col-lg-11" style={{ float: "left" }}>
              <label>Entered By</label>
              <div>
                {/*   select: "Name Salutation MiddleName LastName", */}
                <input
                  value={ReturnFullName(value?.EnteredBy)}
                  disabled
                  className="form-control"
                ></input>
                {/* <b> {BasedOn}</b> */}
              </div>
            </div>
          </div>
          <div class="col-lg-4" style={{ float: "left" }}>
            <div class="col-lg-11" style={{ float: "left" }}>
              <label>Created At</label>
              <div>
                <input
                  value={
                    value?.createdAt
                      ? value?.createdAt
                          .split("T")[0]
                          .split("-")
                          .reverse()
                          .join("-")
                      : ""
                  }
                  disabled
                  className="form-control"
                ></input>
              </div>
            </div>
          </div>
          <div class="col-lg-4" style={{ float: "left" }}>
            <div class="col-lg-11" style={{ float: "left" }}>
              <label>Remark</label>
              <div>
                <input
                  value={value?.Remark}
                  disabled
                  className="form-control"
                ></input>
                {/* <b> {BasedOn}</b> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPolicy;
