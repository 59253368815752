import React, { useEffect, useState } from "react";
import {
  ToastError,
  ToastSusses,
  ToastInfo,
  ToastWarn,
} from "../../Utils/Tost";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../config";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import MultiInsurance from "../RtoGroupChecker/MultiInsurance";
import { returnInsuranceTypeValue } from "../../Utils/usefullFunction";
import Select from "react-select";
import {
  policyPayingTermOpt,
  Business_TypeData,
  NCB_STATUS,
  PA_TYPE,
  IMT_TYPE,
} from "../../Utils/CommonFields";

const CreatePayoutGrid = () => {
  const { state } = useLocation();
  const { view } = state || {};

  const [insurance, setInsurance] = useState(null);
  const today = new Date();
  const lastDay = today.toISOString().substr(0, 10);
  const [isClicked, setIsClicked] = useState(false);
  const [PayoutGrid, setPayoutGrid] = useState({ DEF: lastDay, DET: lastDay });
  const [isDisabled, setIsDisabled] = useState(false);
  const [SelectedInsuranceType, setSelectedInsuranceType] = useState([]);
  const [odDiscount, setOdDiscount] = useState({
    ODDFrom:null,
    ODDTo: null,
  });

  const [ccFrom, setCcFrom] = useState("");
  const [ccTo, setCcTo] = useState("");
  const [PAPremiumFrom, setPAPremiumFrom] = useState("");
  const [PAPremiumTo, setPAPremiumTo] = useState("");
  const [gvwFrom, setGvwFrom] = useState("");
  const [gvwTo, setGvwTo] = useState("");
  const [seaterFrom, setSeaterFrom] = useState("");
  const [seaterTo, setSeaterTo] = useState("");

  const [selectedPolicyType, setSelectedPolicyType] = useState();
  const [selectedBranch, setSelectedBranch] = useState();
  const [FuelTypeOpt, setFuelTypeOpt] = useState([]);
  const [stateOpt, setStateOpt] = useState([]);
  const [cityOpt, setCityOpt] = useState([]);
  const [PolicyTypeOpt, setPolicyTypeOpt] = useState([]);
  const [BranchOpt, setBranchOpt] = useState([]);
  const [RTOGroup, setRTOGroup] = useState(null);
  const [RTOGroupOpt, setRTOGroupOpt] = useState([]);
  const [InsuranceCompany, setInsuranceCompany] = useState({
    _id: "",
    AliasName: "-- Select Insurance Company --",
  });
  const [InsuranceCompanyOpt, setInsuranceCompanyOpt] = useState([]);
  const [CompanyBranch, setCompanyBranch] = useState({
    _id: null,
    BranchName: "-- Select Company Branch --",
  });
  const [CompanyBranchOpt, setCompanyBranchOpt] = useState([]);
  const [MakeModal, setMakeModal] = useState(null);
  const [Modal, setModal] = useState(null);
  const [Varient, setVarient] = useState(null);
  const [PA, setPA] = useState("");

  const [CustomerType, setCustomerType] = useState("");
  const [BasedOn, setBasedOn] = useState("FLAT");
  const [AddDetails, setAddDetails] = useState([]);
  const [IsEditCheck, setIsEditCheck] = useState(false);
  const [NCBSTATUS, setNCBSTATUS] = useState("");
  const [MakeModalOpt, setMakeModalOpt] = useState([]);
  const [ModalOpt, setModalOpt] = useState([]);
  const [VarientOpt, setVarientOpt] = useState([]);
  const [FuelType, setFuelType] = useState(null);
  const [BusinessType, setBusinessType] = useState("");
  const [nillDepth, setNillDepth] = useState("");
  const [IMTType, setIMTType] = useState("");
  const navigate = useNavigate();
  const [LOB, setLOB] = useState("Motor");
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [riskType, setRiskType] = useState("");
  const [IDVFrom, setIDVFrom] = useState("");
  const [IDVTo, setIDVTo] = useState("");
  const [isNonMotorSelected, setIsNonMotorSelected] = useState(false);

  const handleCreate = async () => {
    if (!BasedOn) {
      return ToastWarn("Based On is required");
    }
    try {
      AddDetails.sort((a, b) => new Date(b.DEF) - new Date(a.DEF));
      AddDetails.sort((a, b) => new Date(b.DET) - new Date(a.DET));

      const que = {};
      // if (RTOGroupOpt.length === RTOGroup.length) {
      //   que["isAllRtoGroupSelected"] = true;
      // } else {
      //   que["isAllRtoGroupSelected"] = false;
      // }
      // if (MakeModalOpt.length === MakeModal.length) {
      //   que["isAllMakeModalSelected"] = true;
      // } else {
      //   que["isAllMakeModalSelected"] = false;
      // }
      // if (ModalOpt.length === Modal.length) {
      //   que["isAllModalSelected"] = true;
      // } else {
      //   que["isAllModalSelected"] = false;
      // }
      // if (VarientOpt.length === Varient.length) {
      //   que["isAllVarientSelected"] = true;
      // } else {
      //   que["isAllVarientSelected"] = false;
      // }

      const insType = SelectedInsuranceType.map((item) => item?.value).filter(
        (item) => item !== ""
      );

      const {
        data: { success, message },
      } = await axiosInstance.post("/PayoutGrid", {
        RTOGroup: RTOGroup || null,
        PolicyType: selectedPolicyType ? selectedPolicyType : null,
        Branch: selectedBranch ? selectedBranch : null,
        InsuranceCompany: InsuranceCompany?._id ?? "",
        CompanyBranch: CompanyBranch?._id ?? null,
        // Manufacturer: convertSelectBoxToValueData(Manufacturer),
        MakeModal: MakeModal || null,
        Model: Modal || null,
        Variant: Varient || null,
        // VehicleModal: convertSelectBoxToValueData(VehicleModal),
        InsuranceType: insType.length > 0 ? insType[insType.length - 1] : null,
        AddDetails: AddDetails,
        PA: PA || null,
        CustomerType: CustomerType || null,
        BasedOn,
        LOB: LOB,
        State: selectedState || null,
        City: selectedCity || null,
        RiskType: riskType,
        IDVFrom: IDVFrom,
        IDVTo: IDVTo,

        BusinessType,
        ...odDiscount,
        ...PayoutGrid,
        NCBSTATUS: NCBSTATUS || "",
        FuelType: FuelType || null,
        CCFrom: ccFrom,
        CCTo: ccTo,
        PAPremiumFrom: PAPremiumFrom,
        PAPremiumTo: PAPremiumTo,
        nillDepth: nillDepth,
        IMTType: IMTType || "",
        GVWFrom: gvwFrom,
        GVWTo: gvwTo,
        seaterFrom: seaterFrom,
        seaterTo: seaterTo,
      });

      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/PayoutGrid/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      setIsClicked(false);
      ToastError(error?.response?.data?.message);
      console.log("error", error);
    } finally {
      setIsClicked(false);
    }
  };

  const fetchData = async () => {
    try {
      const [insCompanyRep, policyTypeResp,branchResp, stateResponse] = await Promise.all([
        axiosInstance.get("/InsuranceCompany"),
        axiosInstance.get("/PolicyType"),
        axiosInstance.get("/Branch"),
        axiosInstance.get("/State"),
      ]);

      if (insCompanyRep && insCompanyRep.data && insCompanyRep.data.data) {
        setInsuranceCompanyOpt(insCompanyRep.data.data);
      }
      if (policyTypeResp && policyTypeResp.data && policyTypeResp.data.data) {
        setPolicyTypeOpt(policyTypeResp.data.data);
      }
      if (branchResp && branchResp.data && branchResp.data.data) {
        setBranchOpt(branchResp.data.data);
      }
      if (stateResponse && stateResponse.data && stateResponse.data.data) {
        setStateOpt(stateResponse.data.data);
      }
    } catch (error) {}
  };

  const getCompanyBranch = async () => {
    try {
      if (CompanyBranchOpt.length > 0) {
        return false;
      }

      const {
        data: { data },
      } = await axiosInstance.post(
        "/InsuranceCompany/CompanyBranch/getallcompanyBranch",
        {
          id: InsuranceCompany._id,
        }
      );
      setCompanyBranchOpt([...data]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedState && selectedState._id) {
      axiosInstance
        .get("/RTO/City", { params: { state: selectedState._id } })
        .then((response) => {
          setCityOpt(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching RTO data:", error);
        });
    }
  }, [selectedState]);

  // const getRTOGroupOpt = async (id) => {
  //   const SelectedInsuranceTypeData = SelectedInsuranceType.map(
  //     (ele) => ele.value
  //   ).filter((ele) => ele !== "");

  //   const insuranceType = SelectedInsuranceTypeData.length - 1;

  //   try {
  //     const {
  //       data: { data },
  //     } = await axiosInstance.get(
  //       `/RtoGroupChecker?InsuranceCompany=${id}&InsuranceType=${
  //         SelectedInsuranceTypeData[insuranceType] ?? ""
  //       }`
  //     );

  //     const RTOGroup = await data.map((ele) => {
  //       return {
  //         label: `${ele.GroupName}`,
  //         value: ele._id,
  //       };
  //     });
  //     console.log("RTOGroup",RTOGroup)
  //     setRTOGroupOpt(RTOGroup);
  //   } catch (error) {}
  // };

  useEffect(() => {
    if (
      SelectedInsuranceType &&
      SelectedInsuranceType.length > 0 &&
      SelectedInsuranceType[0].value
    ) {
      const foundData = SelectedInsuranceType[0].data.find(
        (item) => item?._id === SelectedInsuranceType[0]?.value
      );

      if (foundData) {
        setInsurance(foundData);
      }
    }
  }, [SelectedInsuranceType]);

  const handleMotorTypeChange = (e) => {
    const selectedValue = e.target.value;
    setLOB(selectedValue);

    if (selectedValue === "Non-Motor") {
      setIsNonMotorSelected(true);
    } else {
      setIsNonMotorSelected(false);
    }
  };

  const getRTOGroupOpt = async (id) => {
    const SelectedInsuranceTypeData = SelectedInsuranceType.map(
      (ele) => ele.value
    ).filter((ele) => ele !== "");
    // const insuranceType = SelectedInsuranceTypeData.length - 1;
    try {
      const {
        data: { data },
      } = await axiosInstance.post(
        `/PayoutGrid/getRtoGroupCheckerfilterpayout`,
        {
          InsuranceCompany: id,
          InsuranceType: SelectedInsuranceTypeData ?? "",
          PolicyType: selectedPolicyType,
          DEF: PayoutGrid?.DEF,
          DET: PayoutGrid?.DET,
          GVWFrom: gvwFrom,
          GVWTo: gvwTo,
          seaterFrom: seaterFrom,
          seaterTo: seaterTo,
        }
      );
      const RTOGroup = await data.map((ele) => {
        return {
          label: `${ele.GroupName} ( ${ele._id} )`,
          value: ele._id,
        };
      });
      setRTOGroupOpt(RTOGroup);
    } catch (error) {}
  };

  const getDataInsuranceType = async (InsuranceCategory) => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(
        `/InsuranceType?Root=true&InsuranceCategory=${InsuranceCategory}`
      );
      setSelectedInsuranceType([{ data, value: "" }]);
    } catch (error) {}
  };
  const getFuelType = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/MakeModal/getAllFuelTypewithname");

      if (data && data.length > 0 && data[0].FuelType) {
        const fuelType = data.map((item) => ({
          value: item._id,
          label: item.FuelType,
        }));

        setFuelTypeOpt(fuelType);
      } else {
        console.log("FuelType not found or data is empty");
      }
    } catch (error) {
      console.error("Error fetching fuel types:", error);
    }
  };

  useEffect(() => {
    getDataInsuranceType(LOB);
    getFuelType();
  }, [LOB]);
  useEffect(() => {
    fetchData();
    // getManufacturer();
  }, []);

  useEffect(() => {
    if (InsuranceCompany._id) {
      getRTOGroupOpt(InsuranceCompany._id);
    }
  }, [
    InsuranceCompany,
    SelectedInsuranceType,
    selectedPolicyType,
    seaterFrom,
    seaterTo,
    gvwFrom,
    gvwTo,
    PayoutGrid?.DEF,
    PayoutGrid?.DET,
  ]);

  const addHandler = (e) => {
    e.preventDefault();
    const obj = { DEF: lastDay };
    const objt = { DET: lastDay };

    setAddDetails([...AddDetails, PayoutGrid]);
    setPayoutGrid({ ...obj });
    if (IsEditCheck) {
      setIsEditCheck(false);

      return ToastSusses("Edit Successfully");
    }
    return ToastSusses("Add Successfully");
  };
  const editHandler = (index, IsEdit) => {
    if (IsEditCheck) {
      return ToastWarn("Please First Add Selected Data");
    }
    const AddDetailsData = AddDetails;
    const restData = AddDetailsData.splice(index, 1);
    setAddDetails([...AddDetailsData]);

    if (IsEdit) {
      setIsEditCheck(true);
      setPayoutGrid(...restData);
      return ToastInfo("Selected");
    }
    return ToastSusses("Delete Successfully");
  };
  const onChangeAddDetails = (e) => {
    setPayoutGrid({ ...PayoutGrid, [e.target.name]: e.target.value });
  };
  const MakeModalPromiseOptions = async (options, inputValue = "") => {
    try {
      if (!inputValue) {
        return options;
      }

      const id = returnInsuranceTypeValue(SelectedInsuranceType);

      if (!id) {
        return;
      }
      const {
        data: { data },
      } = await axiosInstance.post("/MakeModal/getDropDownData", {
        inputData: inputValue,
        InsuranceType: id,
      });

      return data;
    } catch (error) {}
  };

  const getMakeModal = async (InsuranceType, reset = false) => {
    if (!InsuranceType) {
      setMakeModalOpt([]);
      return;
    }

    if (!reset) {
      setMakeModal(null);
    }

    try {
      const {
        data: { data },
      } = await axiosInstance.post("/Variant/getMake", { id: InsuranceType });
      const options = data.map((item) => ({
        label: item.Make,
        value: item._id,
      }));

      setMakeModalOpt(options ?? []);
    } catch (error) {
      console.error("Error fetching make modal options:", error);
    }
  };

  useEffect(() => {
    const getModal = async (insuranceType, makeIds) => {
      try {
        const {
          data: { data = [] },
        } = await axiosInstance.post("/Variant/getModelForMultiSelectBox", {
          insuranceType,
          makeIds,
        });

        const formattedData = data.map((item) => ({
          label: item.Model,
          value: item._id,
        }));
        setModalOpt(formattedData);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (
      SelectedInsuranceType &&
      SelectedInsuranceType.length > 0 &&
      SelectedInsuranceType[0].value &&
      MakeModal
    ) {
      const makeIds = [MakeModal];

      getModal(SelectedInsuranceType[0].value, makeIds);
    }
  }, [MakeModal]);

  useEffect(() => {
    const getVariant = async (insuranceType, makeIds, modelIds) => {
      try {
        const {
          data: { data },
        } = await axiosInstance.post("/Variant/getVariantForMultiSelectBox", {
          insuranceType,
          makeIds,
          modelIds,
        });
        const formattedData = data.map((item) => ({
          label: item.VariantName,
          value: item._id,
        }));

        setVarientOpt(formattedData);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (
      SelectedInsuranceType &&
      SelectedInsuranceType.length > 0 &&
      SelectedInsuranceType[0].value &&
      MakeModal && // Check if MakeModal has a value
      Modal // Check if Modal has a value
    ) {
      getVariant(SelectedInsuranceType[0].value, MakeModal, Modal); // Call with arrays
    }
  }, [SelectedInsuranceType, Modal, MakeModal]);
  return (
    <>
      <Header />
      <div className="MainDiv MainDivPadding">
        <div className="container-fluid">
          <div className="row ">
            <section
              className="push CreateCard  p-3"
              style={{
                position: "relative",
                transition: " margin-left .5s",
                zoom: "90%",
              }}
            >
              <div className="">
                <div className="row mx-2 ">
                  <h5 className="my-3">{"Create PayoutGrid"}</h5>
                  {/* Employee Information */}
                  <hr className="my-1" />

                  {/* Employee Information */}

                  <div className="col-md-4 align-items-center">
                    <label>DEF Date</label>
                    <input
                      disabled={view}
                      className="form-control "
                      type="Date"
                      name="DEF"
                      value={PayoutGrid.DEF}
                      onChange={(e) => onChangeAddDetails(e)}
                      placeholder="Enter From Age "
                      required //done
                    />
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label>DET Date</label>
                    <input
                      disabled={view}
                      className="form-control "
                      type="Date"
                      name="DET"
                      value={PayoutGrid.DET}
                      onChange={(e) => onChangeAddDetails(e)}
                      placeholder="Enter From Age "
                      required //done
                    />
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label>Policy Type</label>
                    <div className="form-group">
                      <select
                        className="form-select"
                        value={selectedPolicyType}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          setSelectedPolicyType(selectedValue);
                        }}
                      >
                        <option value="">Select Policy Type</option>
                        {PolicyTypeOpt &&
                          PolicyTypeOpt.length > 0 &&
                          PolicyTypeOpt.map((option) => (
                            <option key={option._id} value={option._id}>
                              {option.PolicyTypeName}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label>Branch</label>
                    <div className="form-group">
                      <select
                        className="form-select"
                        value={selectedBranch}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          setSelectedBranch(selectedValue);
                        }}
                      >
                        <option value="">Select Branch</option>
                        {BranchOpt &&
                          BranchOpt.length > 0 &&
                          BranchOpt.map((option) => (
                            <option key={option._id} value={option._id}>
                              {option.BranchName}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label>
                      Insurance Company <span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      options={[
                        {
                          _id: "",
                          AliasName: "-- Select Insurance Company --",
                        },
                        ...InsuranceCompanyOpt,
                      ]}
                      getOptionValue={(option) => option["_id"]}
                      getOptionLabel={(option) => option["AliasName"]}
                      onChange={(ele) => {
                        setInsuranceCompany(ele);
                        setSelectedInsuranceType((pre) => {
                          let { data } = pre[0];
                          return [{ data, value: "" }];
                        });
                        if (ele._id) {
                          setRTOGroup(null);
                          // getRTOGroupOpt({ InsuranceCompanyF: ele?._id });
                        }
                      }}
                      value={InsuranceCompany}
                    />
                  </div>
                  {/* companyranch */}

                  <div
                    className="col-md-4 align-items-center"
                    onClick={() => {
                      getCompanyBranch();
                    }}
                  >
                    <label>Insurer Branch</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      options={[
                        {
                          _id: "",
                          BranchName: "-- Select Company Branch --",
                        },
                        ...CompanyBranchOpt,
                      ]}
                      getOptionValue={(option) => option["_id"]}
                      getOptionLabel={(option) => option["BranchName"]}
                      onChange={(ele) => {
                        setCompanyBranch(ele);
                      }}
                      value={CompanyBranch}
                    />
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label>LOB</label>
                    <select
                      className="form-select"
                      value={LOB}
                      onChange={handleMotorTypeChange}
                      disabled={isDisabled || view}
                      style={{
                        backgroundColor: isDisabled || view ? "#90cbf9" : "",
                      }}
                    >
                      <option value="Motor">Motor</option>
                      <option value="Non-Motor">Non-Motor</option>
                    </select>
                  </div>

                  {/* <div className="col-md-4 align-items-center">
                    <label>State</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      options={[
                        {
                          _id: "",
                          AliasName: "-- Select State --",
                        },
                        ...stateOpt,
                      ]}
                      getOptionValue={(option) => option["_id"]}
                      getOptionLabel={(option) => option["StateName"]}
                      onChange={(ele) => {}}
                      // value={InsuranceCompany}
                    />
                  </div> */}

                  {SelectedInsuranceType &&
                    SelectedInsuranceType?.length > 0 &&
                    SelectedInsuranceType.map(
                      (data, index) =>
                        data &&
                        data?.data?.length > 0 && (
                          <MultiInsurance
                            key={index}
                            Options={data}
                            setSelectedInsuranceType={setSelectedInsuranceType}
                            view={view}
                            SelectedInsuranceType={SelectedInsuranceType}
                            index={index}
                            OptionsValue={data.value}
                            InsuranceCompany={InsuranceCompany?._id}
                            getMakeModal={getMakeModal}
                          />
                        )
                    )}

                  <div className="col-md-2 align-items-center">
                    <label htmlFor="gvwFromInput">GVW From</label>
                    <input
                      disabled={isNonMotorSelected || view}
                      type="number"
                      id="gvwFromInput"
                      className="form-control"
                      value={gvwFrom}
                      onChange={(e) => setGvwFrom(e.target.value)}
                      placeholder="Enter GVW From"
                    />
                  </div>
                  <div className="col-md-2 align-items-center">
                    <label htmlFor="gvwToInput">GVW To</label>
                    <input
                      disabled={isNonMotorSelected || view}
                      type="number"
                      id="gvwToInput"
                      className="form-control"
                      value={gvwTo}
                      onChange={(e) => setGvwTo(e.target.value)}
                      placeholder="Enter GVW To"
                    />
                  </div>

                  <div className="col-md-2 align-items-center">
                    <label htmlFor="seaterFromInput">Seater From</label>
                    <input
                      disabled={isNonMotorSelected || view}
                      type="number"
                      id="seaterFromInput"
                      className="form-control"
                      value={seaterFrom}
                      onChange={(e) => setSeaterFrom(e.target.value)}
                      placeholder="Enter Seater From"
                    />
                  </div>
                  <div className="col-md-2 align-items-center">
                    <label htmlFor="seaterToInput">Seater To</label>
                    <input
                      disabled={isNonMotorSelected || view}
                      type="number"
                      id="seaterToInput"
                      className="form-control"
                      value={seaterTo}
                      onChange={(e) => setSeaterTo(e.target.value)}
                      placeholder="Enter Seater To"
                    />
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label htmlFor="rtoGroupSelect">RTO Cluster</label>

                    <select
                      disabled={isNonMotorSelected || view}
                      id="rtoGroupSelect"
                      className="form-select"
                      value={RTOGroup}
                      onChange={(e) => setRTOGroup(e.target.value)}
                    >
                      <option value="">Select</option>
                      {RTOGroupOpt.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-4 align-items-center"></div>

                  <div className="col-md-4 align-items-center">
                    <label htmlFor="makeSelect">Make</label>
                    <select
                      disabled={isNonMotorSelected || view}
                      id="makeSelect"
                      className="form-select"
                      value={MakeModal}
                      onChange={(e) => {
                        setMakeModal(e.target.value);
                        setVarient(null);
                        setModal(null);
                        setModalOpt([]);
                        setVarientOpt([]);
                      }}
                    >
                      <option value="">Select</option>
                      {MakeModalOpt.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label htmlFor="modelSelect">Model</label>
                    <select
                      disabled={isNonMotorSelected || view}
                      id="modelSelect"
                      className="form-select"
                      value={Modal} // Assuming Modal is now a single value
                      onChange={(e) => {
                        setModal(e.target.value);
                        setVarient(null);
                        setVarientOpt([]);
                      }}
                    >
                      <option value="">Select</option>
                      {ModalOpt.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label htmlFor="variantSelect">Variant</label>
                    <select
                      disabled={isNonMotorSelected || view}
                      id="variantSelect"
                      className="form-select"
                      value={Varient} // Assuming Varient is now a single value
                      onChange={(e) => setVarient(e.target.value)}
                    >
                      <option value="">Select</option>
                      {VarientOpt.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-2 align-items-center">
                    <label htmlFor="odDiscountInput">ODD % (From)</label>
                    <input
                      type="number"
                      id="odDiscountInput"
                      className="form-control"
                      value={odDiscount?.ODDFrom || ""} // Make sure to define `odDiscount` in your state
                      onChange={(e) =>
                        setOdDiscount((prev) => ({
                          ...prev,
                          ODDFrom: e.target.value,
                        }))
                      } // Make sure to define `setOdDiscount` in your state setter
                      placeholder="Enter ODD %"
                    />
                  </div>

                  <div className="col-md-2 align-items-center">
                    <label htmlFor="odDiscountInput">ODD % (To)</label>
                    <input
                      type="number"
                      id="odDiscountInput"
                      className="form-control"
                      value={odDiscount?.ODDTo || ""} // Make sure to define `odDiscount` in your state
                      onChange={(e) =>
                        setOdDiscount((prev) => ({
                          ...prev,
                          ODDTo: e.target.value,
                        }))
                      }
                      placeholder="Enter ODD %"
                    />
                  </div>

                  {/* cc gvw seater */}

                  <div className="col-md-2 align-items-center">
                    <label htmlFor="ccFromInput">CC From</label>
                    <input
                      disabled={isNonMotorSelected || view}
                      type="number"
                      id="ccFromInput"
                      className="form-control"
                      value={ccFrom}
                      onChange={(e) => setCcFrom(e.target.value)}
                      placeholder="Enter CC From"
                    />
                  </div>
                  <div className="col-md-2 align-items-center">
                    <label htmlFor="ccToInput">CC To</label>
                    <input
                      disabled={isNonMotorSelected || view}
                      type="number"
                      id="ccToInput"
                      className="form-control"
                      value={ccTo}
                      onChange={(e) => setCcTo(e.target.value)}
                      placeholder="Enter CC To"
                    />
                  </div>

                  <div className="col-md-2 align-items-center">
                    <label htmlFor="PAPremiumFromInput">PAPremium From</label>
                    <input
                      disabled={isNonMotorSelected || view}
                      type="number"
                      id="PAPremiumFromInput"
                      className="form-control"
                      value={PAPremiumFrom}
                      onChange={(e) => setPAPremiumFrom(e.target.value)}
                      placeholder="Enter PAPremium From"
                    />
                  </div>
                  <div className="col-md-2 align-items-center">
                    <label htmlFor="PAPremiumToInput">PAPremium To</label>
                    <input
                      disabled={isNonMotorSelected || view}
                      type="number"
                      id="PAPremiumToInput"
                      className="form-control"
                      value={PAPremiumTo}
                      onChange={(e) => setPAPremiumTo(e.target.value)}
                      placeholder="Enter PAPremium To"
                    />
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label htmlFor="paTypeSelect">PA Type</label>
                    <select
                      disabled={isNonMotorSelected || view}
                      id="paTypeSelect"
                      className="form-select"
                      value={PA}
                      onChange={(e) => setPA(e.target.value)}
                    >
                      <option value="">Select</option>
                      {PA_TYPE.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label htmlFor="paTypeSelect">Customer Type</label>
                    <select
                      disabled={view}
                      id="CustomerTypeSelect"
                      className="form-select"
                      value={CustomerType}
                      onChange={(e) => setCustomerType(e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Individual">Individual</option>
                      <option value="Corporate">Corporate</option>
                    </select>
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label htmlFor="ncbStatusSelect">NCB STATUS</label>
                    <select
                      disabled={view}
                      id="ncbStatusSelect"
                      className="form-select"
                      value={NCBSTATUS}
                      onChange={(e) => setNCBSTATUS(e.target.value)}
                    >
                      <option value="">Select</option>
                      {NCB_STATUS.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label htmlFor="fuelTypeSelect">Fuel Type</label>
                    <select
                      disabled={isNonMotorSelected || view}
                      id="fuelTypeSelect"
                      className="form-select"
                      value={FuelType} // Assuming FuelType is now a single value
                      onChange={(e) => setFuelType(e.target.value)}
                    >
                      <option value="">Select</option>
                      {FuelTypeOpt.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label htmlFor="businessTypeSelect">Business Type</label>
                    <select
                      id="businessTypeSelect"
                      className="form-select"
                      value={BusinessType}
                      onChange={(e) => setBusinessType(e.target.value)}
                    >
                      <option value="">Select</option>
                      {Business_TypeData.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label required>Nil Dep</label>

                    <select
                      disabled={isNonMotorSelected || view}
                      class="form-select"
                      aria-label="Default select example"
                      name="nillDepth"
                      value={nillDepth}
                      onChange={(e) => setNillDepth(e.target.value)}
                      placeholder="Nil Dep"
                    >
                      <option value="">---- Select Nil Dep ---</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>

                  {/* imttype */}

                  {insurance &&
                    insurance.IMTType &&
                    insurance.IMTType.toLocaleLowerCase().includes("yes") && (
                      <div className="col-md-4 align-items-center">
                        <label>IMT Type</label>
                        <select
                          disabled={isNonMotorSelected || view}
                          className="form-select"
                          aria-label="Default select example"
                          name="IMTType"
                          value={IMTType}
                          onChange={(e) => setIMTType(e.target.value)}
                          placeholder="IMTType"
                        >
                          <option value="">-- Select IMT Type --</option>
                          {IMT_TYPE.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}

                  <div className="col-md-2 align-items-center">
                    <label>IDV/Sum Insured From</label>
                    <input
                      disabled={!isNonMotorSelected || view}
                      className="form-control"
                      type="number"
                      name="IDVFrom"
                      value={IDVFrom}
                      onChange={(e) => setIDVFrom(e.target.value)}
                      placeholder="Enter IDV From"
                    />
                  </div>

                  <div className="col-md-2 align-items-center">
                    <label>IDV/Sum Insured To</label>
                    <input
                      disabled={!isNonMotorSelected || view}
                      className="form-control"
                      type="number"
                      name="IDVTo"
                      value={IDVTo}
                      onChange={(e) => setIDVTo(e.target.value)}
                      placeholder="Enter IDV To"
                    />
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label>State</label>
                    <Select
                      isDisabled={!isNonMotorSelected || view}
                      className="basic-single"
                      classNamePrefix="select"
                      options={[
                        {
                          _id: "",
                          AliasName: "-- Select State --",
                        },
                        ...stateOpt,
                      ]}
                      getOptionValue={(option) => option["_id"]}
                      getOptionLabel={(option) => option["StateName"]}
                      onChange={(ele) => {
                        setSelectedState(ele);
                        setSelectedCity(null);
                      }}
                      value={selectedState}
                    />
                  </div>

                  {/* City Dropdown */}
                  <div className="col-md-4 align-items-center">
                    <label>City</label>
                    <Select
                      isDisabled={!isNonMotorSelected || view}
                      className="basic-single"
                      classNamePrefix="select"
                      options={[
                        {
                          _id: "",
                          RTOName: "-- Select City --",
                        },
                        ...cityOpt,
                      ]}
                      getOptionValue={(option) => option["_id"]}
                      getOptionLabel={(option) => option["RTOName"]}
                      onChange={(ele) => setSelectedCity(ele)}
                      value={selectedCity}
                    />
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label>Risk Type</label>
                    <select
                      disabled={!isNonMotorSelected || view}
                      className="form-select"
                      value={riskType}
                      onChange={(e) => setRiskType(e.target.value)}
                    >
                      <option value="">----- Select Risk Type ----</option>
                      <option value="Preferred">Preferred</option>
                      <option value="Non-Preferred">Non-Preferred</option>
                    </select>
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label>
                      BasedOn <span style={{ color: "red" }}>*</span>{" "}
                    </label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setBasedOn(e.target.value);
                      }}
                      value={BasedOn}
                      disabled={view || AddDetails.length > 0}
                    >
                      <option value="">----Select----</option>
                      <option value="NET">NET</option>
                      <option value="OD">OD</option>
                      <option value="TP">TP</option>
                      <option value="OD+TP">OD + TP </option>
                      <option value="OD+TP (Exc. PA)"> OD+TP (Exc. PA)</option>
                      <option value="FLAT">FLAT</option>
                    </select>
                  </div>

                  <div className="col-md-3 align-items-center">
                    <label htmlFor="policyPayingTerm">
                      PPT (Policy Paying Term)
                    </label>
                    <select
                      disabled={view}
                      id="policyPayingTerm"
                      class="form-select"
                      aria-label="Default select example"
                      name="policyPayingTerm"
                      value={PayoutGrid?.policyPayingTerm}
                      onChange={(e) => {
                        onChangeAddDetails(e);
                      }}
                    >
                      <option value="">-- Policy Paying Term --</option>
                      {policyPayingTermOpt.map((item) => (
                        <option value={item.value}>{item.label}</option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-2 align-items-center">
                    <label required>From Age</label>
                    <input
                      disabled={view}
                      className="form-control "
                      type="Number"
                      name="FromAge"
                      value={PayoutGrid?.FromAge || ""}
                      onChange={(e) => onChangeAddDetails(e)}
                      placeholder="Enter From Age "
                      required //done
                      min={0}
                      step=".01"
                    />
                  </div>
                  <div className="col-md-2 align-items-center">
                    <label required>To Age</label>
                    <input
                      disabled={view}
                      className="form-control "
                      type="Number"
                      name="ToAge"
                      value={PayoutGrid.ToAge || ""}
                      onChange={(e) => onChangeAddDetails(e)}
                      placeholder="Enter To Age"
                      required //done
                      min={0}
                      step=".01"
                    />
                  </div>

                  <div className="col-md-2 align-items-center">
                    <label>Receivable</label>
                    <div className="d-flex gap-3">
                      {BasedOn.split("+").map((ele, i) => (
                        <input
                          disabled={view}
                          className="form-control"
                          style={{
                            width: "125px",
                          }}
                          type="Number"
                          key={ele}
                          name={i === 0 ? `Reciveable` : `ReciveableTP`}
                          value={
                            i === 0
                              ? PayoutGrid[`Reciveable`] ?? ""
                              : PayoutGrid[`ReciveableTP`] ?? ""
                          }
                          onChange={(e) => onChangeAddDetails(e)}
                          placeholder={`${ele} `}
                          required //done
                          min={0}
                          step=".01"
                        />
                      ))}
                    </div>
                  </div>

                  <div className="col-md-2 mx-5 align-items-center">
                    <label>Inflow (Spl. Apvl)</label>
                    <div className="d-flex gap-3">
                      {BasedOn.split("+").map((ele, i) => (
                        <input
                          disabled={view}
                          className="form-control"
                          style={{
                            width: "125px",
                          }}
                          type="Number"
                          key={ele}
                          name={i === 0 ? `InflowSpecial` : `InflowSpecialTP`}
                          value={
                            i === 0
                              ? PayoutGrid[`InflowSpecial`] ?? ""
                              : PayoutGrid[`InflowSpecialTP`] ?? ""
                          }
                          onChange={(e) => onChangeAddDetails(e)}
                          placeholder={`${ele} `}
                          required
                          min={0}
                          step=".01"
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="d-flex gap-3 ">
            <button
              type="submit"
              className="btn mybtn"
              // style={{ marginTop: 28 }}

              onClick={() => navigate("/admin/PayoutGrid/data")}
            >
              Back
            </button>
            {!view && (
              <button
                type="submit"
                className="btn mybtn"
                disabled={isClicked}
                onClick={() => {
                  setIsClicked(true);
                  handleCreate();
                }}
              >
                Submit
              </button>
            )}
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default CreatePayoutGrid;
