import React, { useEffect, useState } from "react";
import { REACT_APP_FileURL, axiosInstance } from "../../config";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import SearchInputFilter from "../../Utils/SearchInputFilter";
import Select from "react-select";
// import "./Purchase.css";

import { Link } from "react-router-dom";
import {
  ReturnFullName,
  convertDateStringToDate,
  removeEmptyValues,
  reversDate,
} from "../../Utils/usefullFunction";
import { RxReset } from "react-icons/rx";
import { BiSearchAlt } from "react-icons/bi";
import MultiInsurance from "../RtoGroupChecker/MultiInsurance";
import NewUnbilledCase from "../../Utils/NewUnbilledCase";
import DateRange from "../DateRangeModule/DateRange";
const UnbilledCase = () => {
  const today = new Date();
  // 2023-05-05

  let TotalAmountOfSelectPolicy = 0;
  const lastDay = today.toISOString().substr(0, 10);
  const [data, setData] = useState([]);

  const [SelectedInsuranceType, setSelectedInsuranceType] = useState([]);
  const [PolicyTypeOpt, setPolicyTypeOpt] = useState([]);
  const [POSOpt, setPOSOpt] = useState([]);
  const [BranchOpt, setBranchOpt] = useState([]);

  const [IsFirstRendering, setIsFirstRendering] = useState(true);
  const [inputData, setInput] = useState("");
  const [InsuranceCompanyOpt, setInsuranceCompanyOpt] = useState([
    {
      _id: "",
      Name: "-- Select Insurance Company --",
    },
  ]);
  const [InsuranceCompany, setInsuranceCompany] = useState({
    _id: "",
    Name: " -- Select Insurance Company --",
  });

  const [POS, setPOS] = useState({
    _id: "",
    Name: "-- Select POS --",
  });
  const [Branch, setBranch] = useState({
    _id: "",
    BranchName: "-- Select Branch --",
  });
  const [loading, setLoading] = useState(false);
  const [Policy, setPolicy] = useState({
    startDate: lastDay,
    endDate: lastDay,
  });

  const [totalDocs, setTotalDocs] = useState(0);

  const getInsuranceCompany = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/InsuranceCompany");
      setInsuranceCompanyOpt([
        {
          _id: "",
          Name: "-- Select Insurance Company --",
        },
        ...data,
      ]);
    } catch (error) { }
  };

  const getPolicyType = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/PolicyType");
      setPolicyTypeOpt(data);
    } catch (error) { }
  };

  const getPos = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.post("/Pos/getPosByField", {
        branch: Branch?._id,
        showFiled: {
          Name: 1,
          Salutation: 1,
          MiddleName: 1,
          LastName: 1,
          _id: 1,
        },
      });

      const POS = data.map(
        ({
          Name = "",
          Salutation = "",
          MiddleName = "",
          LastName = "",
          _id,
        }) => {
          return {
            Name: ` ${Salutation} ${Name} ${MiddleName} ${LastName}`,
            _id,
          };
        }
      );
      setPOSOpt([
        {
          _id: "",
          Name: "-- Select POS --",
        },
        ...POS,
      ]);
    } catch (error) { }
  };

  useEffect(() => {
    if (Branch?._id) {
      getPos();
    }
  }, [Branch]);


  const getBranch = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(`/Branch`);
      setBranchOpt([
        {
          _id: "",
          BranchName: "-- Select Branch --",
        },
        ...data,
      ]);
    } catch (error) { }
  };
  const filterDataHandler = async ({
    EraserHandler = false,
    StopLoading = false,
  }) => {
    const SelectedInsuranceTypeData = SelectedInsuranceType.map(
      (ele) => ele.value
    ).filter((ele) => ele !== "");

    try {
      !StopLoading && setLoading(true);
      removeEmptyValues(Policy);

      let que = {};

      if (InsuranceCompany["_id"]) {
        que["InsuranceCompany"] = InsuranceCompany["_id"];
      }
      if (POS["_id"]) {
        que["POS"] = POS["_id"];
      }

      if (Branch["_id"]) {
        que["Branch"] = Branch["_id"];
      }
 
      if (!EraserHandler) {
        que = {
          ...que,
          ...Policy,
          InsuranceType: SelectedInsuranceTypeData,
        };
      } else {
        que = {};
        que["startDate"] = lastDay;
        que["endDate"] = lastDay;
    
        que["InsuranceType"] = [];
        setInsuranceCompany({
          _id: "",
          Name: " -- Select Insurance Company --",
        });

        setPOS({
          _id: "",
          Name: "-- Select POS --",
        });
        setBranch({
          _id: "",
          BranchName: "-- Select Branch --",
        });
      }

      const {
        data: { data, totalDocs },
      } = await axiosInstance.post("/Policy/getUnbilledCase", {
        inputData,
        ...que,
      });

      setTotalDocs(totalDocs);
      setData(data);

      !StopLoading && setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const filterDataHandlerForExcel = async () => {
    const SelectedInsuranceTypeData = SelectedInsuranceType.map(
      (ele) => ele.value
    ).filter((ele) => ele !== "");

    try {
      removeEmptyValues(Policy);

      let que = {};

      if (InsuranceCompany["_id"]) {
        que["InsuranceCompany"] = InsuranceCompany["_id"];
      }
      if (POS["_id"]) {
        que["POS"] = POS["_id"];
      }

      if (Branch["_id"]) {
        que["Branch"] = Branch["_id"];
      }
      que = {
        ...que,
        ...Policy,
        InsuranceType: SelectedInsuranceTypeData,
      };

      const {
        data: { data },
      } = await axiosInstance.post("/Policy/getUnbilledCase", {
        inputData,
        ...que,
      });
      return data;
    } catch (error) { }
  };
  const EraserHandler = async () => {
    const newObj = {
      startDate: lastDay,
      endDate: lastDay,
      InsuranceCompany: "",
      PolicyType: "",
     
    };
    setInput("");

    setPolicy(newObj);
    filterDataHandler({ EraserHandler: true });
    const reSetData = { data: SelectedInsuranceType[0]["data"], value: "" };
    setSelectedInsuranceType((pre) => [reSetData]);
  };

  const getDataInsuranceType = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/InsuranceType?Root=true");
      setSelectedInsuranceType([{ data, value: "" }]);
    } catch (error) { }
  };

  const callInsuranceData = async (InsuranceType) => {
    const length = InsuranceType?.length;
    if (length) {
      const myInsuranceType = [];

      try {
        const {
          data: { data },
        } = await axiosInstance.get("/InsuranceType?Root=true");
        myInsuranceType.push({ data, value: InsuranceType[0] });
      } catch (error) { }
      for (let index = 1; index < length; index++) {
        try {
          const {
            data: { data = [] },
          } = await axiosInstance.get(
            `/InsuranceType?InsuranceUnder=${InsuranceType[index - 1]}`
          );
          await myInsuranceType.push({
            data,
            value: InsuranceType[index],
          });
        } catch (error) { }
      }

      return setSelectedInsuranceType([
        ...SelectedInsuranceType,
        ...myInsuranceType,
      ]);
    } else {
      getDataInsuranceType();
    }
  };
  useEffect(() => {
    if (IsFirstRendering) {
      setIsFirstRendering(false);
      filterDataHandler({});
    } else {
      filterDataHandler({
        notCallOther: false,
      });
    }
  }, []);
  useEffect(() => {
    callInsuranceData();
    getInsuranceCompany();
    getPos();
    getPolicyType();
    getBranch();
  }, []);


  const setDateHandler = (ranges) => {
    setPolicy({
      ...Policy,
      startDate: convertDateStringToDate(ranges.startDate),
      endDate: convertDateStringToDate(ranges.endDate),
    });
  };
  const onChangeSetValue = (e) => {
    setPolicy({ ...Policy, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (IsFirstRendering) {
      return;
    }
    const timer = setTimeout(
      () => filterDataHandler({ EraserHandler: false, StopLoading: true }),
      1000
    );
    return () => clearTimeout(timer);
  }, [inputData]);

  return (
    <>

      <Header />
      <div className="MainDivPadding">
        <div className="container-fluid mt-4">
          {
            <div className="row">
              <div className="">
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "baseline",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <SearchInputFilter
                      setInput={setInput}
                      inputData={inputData}
                      PlaceHolder="Policy No.,Vehicle No.,Insuree Name*"
                    />

                    <div>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        options={InsuranceCompanyOpt}
                        getOptionValue={(option) => option["_id"]}
                        getOptionLabel={(option) => option["Name"]}
                        onChange={setInsuranceCompany}
                        value={InsuranceCompany}
                        styles={{ backgroundColor: "#F5F5F6", height: "42px" }}
                      />
                    </div>

                    <div className="">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        options={BranchOpt}
                        getOptionValue={(option) => option["_id"]}
                        getOptionLabel={(option) => option["BranchName"]}
                        value={Branch}
                        onChange={setBranch}
                        styles={{ height: "42px" }}
                      />
                    </div>

                    <div className="">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        options={POSOpt}
                        getOptionValue={(option) => option["_id"]}
                        getOptionLabel={(option) => option["Name"]}
                        onChange={setPOS}
                        value={POS}
                        styles={{ height: "42px" }}
                      />
                    </div>


                
                    <div className="col-lg-2">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        name="PolicyType"
                        value={Policy?.PolicyType}
                        onChange={(e) => onChangeSetValue(e)}
                        required
                        styles={{ backgroundColor: "#F5F5F6", height: "42px" }}
                      >
                        <option value=""> Policy Type</option>
                        {PolicyTypeOpt &&
                          PolicyTypeOpt.map((data) => (
                            <option value={data._id}>
                              {data.PolicyTypeName}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between pt-2 align-items-center">
                    <div className="d-flex gap-2 ">
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "row",
                          gap: "10px",
                        }}
                      >
                        <input
                          className="form-control "
                          type="date"
                          name="startDate"
                          style={{ backgroundColor: "#F5F5F6", height: "42px" }}
                          value={Policy?.startDate}
                          onChange={(e) => onChangeSetValue(e)}
                          required //done
                        />

                        <p>-</p>
                        <input
                          className="form-control "
                          type="date"
                          name="endDate"
                          style={{ backgroundColor: "#F5F5F6", height: "42px" }}
                          value={Policy?.endDate}
                          onChange={(e) => onChangeSetValue(e)}
                          required //done
                        />
                        <DateRange setDateHandler={setDateHandler} />
                      </div>
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "baseline",
                          flexDirection: "row",
                          gap: "10px",
                        }}
                      >
                        {SelectedInsuranceType &&
                          SelectedInsuranceType?.length > 0 &&
                          SelectedInsuranceType.map(
                            (data, index) =>
                              data &&
                              data?.data?.length > 0 && (
                                <MultiInsurance
                                  key={data.value}
                                  Options={data}
                                  setSelectedInsuranceType={
                                    setSelectedInsuranceType
                                  }
                                  SelectedInsuranceType={SelectedInsuranceType}
                                  index={index}
                                  OptionsValue={data.value}
                                  IsFilter={true}
                                  ShowLabel={false}
                                />
                              )
                          )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "end",
                      }}
                    >
                      <NewUnbilledCase
                        fileName={`Unbilled-Policy-${Policy?.startDate}-To-${Policy?.endDate}`}
                        filterDataHandlerForExcel={filterDataHandlerForExcel}
                      />
                      <BiSearchAlt
                        size={32}
                        onClick={() => filterDataHandler({})}
                        style={{
                          backgroundColor: "#174379",
                          color: "#fff",
                          padding: "5px",
                          borderRadius: "5px",
                          marginTop: "-5px",
                        }}
                      />{" "}
                      <Link>
                        <RxReset
                          onClick={() => EraserHandler()}
                          size={32}
                          style={{
                            backgroundColor: "#E8960F",
                            color: "#fff",
                            padding: "5px",
                            borderRadius: "5px",
                            marginTop: "-5px",
                          }}
                        />
                      </Link>
                    </div>
                  </div>
                </div>

                {loading ? (
                  <div
                    className=" p-2 "
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "80vh",
                    }}
                  >
                    <TableSkeleton />
                  </div>
                ) : (
                  <div
                    className=" p-2 "
                    style={{
                      zoom: "70%",
                    }}
                  >
                    {data && (
                      <table
                        class="table table-striped   table-bordered  overflow-x mt-3"
                        id="table-to-xls"
                      >
                        <thead>
                          <tr>
                            <th scope="col">S/N</th>
                            <th scope="col">Date</th>
                            <th scope="col">Policy Details</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col">Vehicle No.</th>
                            <th scope="col">Amount To Pay</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data &&
                            data?.length !== 0 &&
                            data.map((data, index) => {
                              TotalAmountOfSelectPolicy +=
                                data?.Commission?.TotalToGiveAmount ?? 0;
                              return (
                                <tr key={data._id}>
                                  <th>{index + 1}</th>

                                  <th>{reversDate(data?.IssueDate)}</th>

                                  <th>
                                    <tr> {data?.InsuranceCompany?.AliasName}</tr>
                                    <tr>
                                      {" "}
                                      {data?.InsuranceType?.InsuranceType}
                                    </tr>
                                    <tr> RSD:-{data?.RSD}</tr>
                                    <tr> {data?.InsureeName}</tr>
                                  </th>

                                  <th>
                                    <tr>
                                      <Link
                                        to={`${REACT_APP_FileURL}${data.NewPolicyCopy}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {" "}
                                        {data?.PolicyNumber}
                                      </Link>{" "}
                                    </tr>
                                    <tr> {data?.MakeModal?.Name}</tr>
                                    <tr> {ReturnFullName(data?.POS)}</tr>
                                    <tr> {data?.Variant?.VariantName}</tr>
                                    <tr> YOM- {data?.YearOfManufacture}</tr>
                                  </th>

                                  <th style={{ width: '100px' }} >
                                    <tr>Gross </tr>
                                    <tr>Net </tr>
                                    <tr>TP</tr>
                                    <tr>OD</tr>
                                    <tr>Flat</tr>
                                  </th>

                                  <th>
                                    <tr> ₹ {Math.round(data?.GrossPremium)}</tr>
                                    <tr> ₹ {data?.NETPremium}</tr>
                                    <tr> ₹ {data?.TPPremium}</tr>
                                    <tr> ₹ {data?.ODPremium}</tr>
                                    {/* <tr> ₹ {data?.ODPremium}</tr> */}
                                  </th>

                                  <th>
                                    <tr>
                                      <br />
                                    </tr>
                                    {data?.BasedOn?.includes("NET") ? (
                                      <tr>
                                        {data?.Commission?.CommissionToPayNET /
                                          10 +
                                          "X NET"}{" "}
                                      </tr>
                                    ) : (
                                      <tr>
                                        <br />
                                      </tr>
                                    )}
                                    {data?.BasedOn?.includes("TP") ? (
                                      <tr>
                                        {data?.Commission?.CommissionToPayTP /
                                          10 +
                                          "X TP"}{" "}
                                      </tr>
                                    ) : (
                                      <tr>
                                        <br />
                                      </tr>
                                    )}
                                    {data?.BasedOn?.includes("OD") ? (
                                      <tr>
                                        {data?.Commission?.CommissionToPayOD /
                                          10 +
                                          "X OD"}{" "}
                                      </tr>
                                    ) : (
                                      <tr>
                                        <br />
                                      </tr>
                                    )}
                                    {data?.BasedOn?.includes("FLAT") ? (
                                      <tr>
                                        {data?.Commission?.CommissionToPayFLAT +
                                          "FLAT"}{" "}
                                      </tr>
                                    ) : (
                                      <tr>
                                        <br />
                                      </tr>
                                    )}
                                  </th>

                                  <th>
                                    <tr>
                                      {" "}
                                      {data?.PaymentMode?.TypeOfPaymentMode ??
                                        data?.PaymentMode}
                                    </tr>
                                    <tr> {data?.PolicyType?.Name}</tr>
                                    <tr> {data?.VehicleNumber}</tr>
                                    {/* <tr> {data?.ODPremium}</tr> */}
                                  </th>

                                  <th>
                                    <tr>
                                      ₹ {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data?.Commission?.TotalToGiveAmount)}
                                    </tr>

                                  </th>

                                </tr>
                              );
                            })}
                          <tr>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>

                            <th scope="row">
                              ₹ {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(TotalAmountOfSelectPolicy ?? 0)}
                            </th>

                          </tr>
                        </tbody>
                      </table>
                    )}
                    {
                      <div
                        style={{
                          backgroundColor: "#1a2e45d7",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "start",
                          marginTop: "5px",
                        }}
                      ></div>
                    }
                  </div>
                )}
              </div>
            </div>
          }
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UnbilledCase;
