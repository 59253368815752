import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  ReturnFullName,
  convertDateStringToDate,
  coverAmountIntoCurrent,
  reversDate,
} from "../../Utils/usefullFunction";
import { axiosInstance } from "../../config";

import DateRange from "../DateRangeModule/DateRange";
import { Link } from "react-router-dom";
import Select from "react-select";
import { AiFillFilePdf } from "react-icons/ai";
import { BiSearchAlt } from "react-icons/bi";
import { RxReset } from "react-icons/rx";
import TDSExcel from "./TDSExcel";

const TDSReport = () => {
  const today = new Date();
  // 2023-05-05
  const firstDay = `${today.toISOString().substr(0, 7)}-01`;
  const lastDay = today.toISOString().substr(0, 10);
  const [StartDate, setStartDate] = useState(firstDay);
  const [EndDate, setEndDate] = useState(lastDay);
  const [BankOpt, setBankOpt] = useState([]);
  const [Bank, setBank] = useState({
    _id: "",
    Name: "-- Select Bank Account --",
  });
  const [PaymentList, setPaymentList] = useState([]);

  const getDataById = async (Eraser = false) => {
    try {
      if (Eraser) {
        const {
          data: { data: value = {} },
        } = await axiosInstance.get(
          `/PaymentList/getTdsReport?StartDate=${firstDay}&EndDate=${lastDay}`
        );
        setPaymentListHandler(value);
      } else {
        const {
          data: { data: value = {} },
        } = await axiosInstance.get(
          `/PaymentList/getTdsReport?StartDate=${StartDate}&EndDate=${EndDate}&Bank=${
            Bank._id ?? ""
          }`
        );
        setPaymentListHandler(value);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getDataById();
  }, []);
  const setDateHandler = (ranges) => {
    setEndDate(convertDateStringToDate(ranges.endDate));
    setStartDate(convertDateStringToDate(ranges.startDate));
  };

  const setPaymentListHandler = (PaymentList = []) => {
    const myArr = PaymentList?.map((ele) => {
      let { PaymentList = {}, FromModal = "", CashBankAccount = {} } = ele;
      const {
        LedgerEntry: Entry,
        PartyAccount: Account,
        Broker: BrokerDetails,
        Employee: EmployeeDetails,
        _id,
        ...rest
      } = PaymentList;

      let obj;
      if (Entry) {
        const { _id: LedgerEntry, Name } = Entry;
        obj = {
          ...rest,
          LedgerEntry,
          Name,
          PanNumber: "",
        };
      }
      if (BrokerDetails) {
        const { _id: Broker, Name } = BrokerDetails;
        obj = {
          ...rest,
          Broker,
          Name,
          PanNumber: "",
        };
      }

      if (Account) {
        const { _id: PartyAccount, PanNumber } = Account;
        obj = {
          ...rest,
          PartyAccount,
          Name: ReturnFullName(Account),
          PanNumber,
        };
      }

      if (EmployeeDetails) {
        const { _id: Employee, PanNumber } = EmployeeDetails;
        obj = {
          ...rest,
          Employee,
          Name: ReturnFullName(EmployeeDetails),
          PanNumber,
        };
      }
      return {
        EntryDate: ele.EntryDate,
        ...obj,
        FromModal,
        Bank: CashBankAccount,
        _id: ele._id,
      };
    });
   
    setPaymentList(myArr);
  };

  const getBank = async () => {
    const {
      data: { data },
    } = await axiosInstance.get("/Bank");

    setBankOpt([
      {
        _id: "",
        Name: "-- Select Bank Account --",
      },
      ...data,
    ]);
  };
  useEffect(() => {
    getBank();
  }, []);
  return (
    <>
      <Header />
      <div className="container-fluid" style={{ marginTop: "70px" }}>
        <div className="card-body CreateCard" style={{ zoom: "90%" }}>
          <div className="row mx-2 ">
            <div
              className=" p-2 container filters"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "unset",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <div className="d-flex gap-3">
                  <div>
                    <div
                      className=""
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <input
                        className="form-control "
                        type="date"
                        name="startDate"
                        style={{
                          maxWidth: "150px",
                        }}
                        value={StartDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        // placeholder="Enter  PolicyNumber"
                        required //done
                      />

                      <p>-</p>
                      <input
                        className="form-control "
                        type="date"
                        name="endDate"
                        value={EndDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        // placeholder="Enter  PolicyNumber"
                        style={{
                          maxWidth: "150px",
                        }}
                        required //done
                      />
                    </div>
                  </div>
                  <DateRange setDateHandler={setDateHandler} />
                  <Select
                    style={{ backgroundColor: "#F5F5F6" }}
                    className="basic-single"
                    classNamePrefix="select"
                    options={BankOpt}
                    // defaultValue={{
                    //   _id:"-- Please Select Variant --"
                    // }}
                    noOptionsMessage={() => "No Bank Found"}
                    getOptionValue={(option) => option["_id"]}
                    getOptionLabel={(option) => option["Name"]}
                    onChange={setBank}
                    value={Bank}
                  />

                  <div className="">
                    <Link>
                      <AiFillFilePdf
                        // onClick={() => downloadPdf()}
                        size={32}
                        style={{
                          backgroundColor: "#1b9a9a",
                          color: "#fff",
                          padding: "5px",
                          borderRadius: "5px",
                          marginTop: "2px",
                        }}
                      />
                    </Link>
                    &nbsp;
                    <TDSExcel PaymentList={PaymentList} />
                    &nbsp;
                    <BiSearchAlt
                      size={32}
                      onClick={() => getDataById()}
                      style={{
                        backgroundColor: "#174379",
                        color: "#fff",
                        padding: "5px",
                        borderRadius: "5px",
                        marginTop: "2px",
                      }}
                    />{" "}
                    &nbsp;
                    <Link>
                      <RxReset
                        onClick={() => {
                          setEndDate(lastDay);
                          setStartDate(firstDay);
                          setBank({
                            _id: "",
                            Name: "-- Select Bank Account --",
                          });
                          getDataById(true);
                        }}
                        size={32}
                        style={{
                          backgroundColor: "#E8960F",
                          color: "#fff",
                          padding: "5px",
                          borderRadius: "5px",
                          marginTop: "2px",
                        }}
                      />
                    </Link>
                    &nbsp;
                  </div>
                </div>
              </div>

              {/* <div className="row gap-2">
                <div
                  className="searchBox"
                  style={{
                    width: "77px",
                    cursor: "pointer",
                  }}
                  onClick={() => getLedger()}
                >
                  <MdSend />
                </div>
                <div
                  className="searchBox"
                  style={{
                    width: "77px",
                    cursor: "pointer",
                  }}
                  onClick={() => EraserHandler()}
                >
                  <BsEraser />
                </div>
              </div> */}
            </div>
            <div className="col-md-12 align-items-center">
              {
                <table class="table table-striped table-bordered overflow-x mt-3">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Bank</th>
                      <th scope="col">PartyAccount</th>
                      <th scope="col">EntryDate</th>
                      <th scope="col">Bank/Account No/IFSC</th>
                      <th scope="col">Pan Number</th>

                      {/* <th scope="col">TDS Account</th> */}
                      <th scope="col">Section</th>
                      <th scope="col">TDS Percent</th>
                      <th scope="col">Amount</th>

                      <th scope="col">TDS Amount</th>
                      <th scope="col">Without TDS</th>

                      {/* <th scope="col">Narration</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {PaymentList &&
                      PaymentList.length >= 0 &&
                      PaymentList.map((ele, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                            <td>
                              {" "}
                              <Link
                                to={`/admin/PaymentList?_id=${ele?._id}&Mode=true`}
                              >
                                {ele?.Bank?.Name ?? ""}
                              </Link>
                            </td>
                          </td>
                          <td>
                            <td>{ele?.Name ?? ""}</td>
                          </td>

                          <td> {reversDate(ele?.EntryDate)}</td>

                          <td>
                            {ele?.BankDetailName
                              ? ele?.BankDetailName
                              : ele?.BankDetail?.Name &&
                                `${ele?.BankDetail?.AccountHolderName ?? ""}/${
                                  ele?.BankDetail?.Name ?? ""
                                }/${ele?.BankDetail?.IFSC ?? ""}/${
                                  ele?.BankDetail?.BankAccountNo ?? ""
                                }`}
                          </td>
                          {/* <td>{ele?.TDSAccount?.Name}</td> */}
                          <td>
                            {ele?.BankDetail?.PanNumber
                              ? ele?.BankDetail?.PanNumber
                              : ele?.PanNumber}
                          </td>
                          <td>{ele?.Section}</td>
                          <td>{ele?.TDSPercent ?? 0}%</td>
                          <td>{coverAmountIntoCurrent(ele?.Account)}</td>

                          <td>{coverAmountIntoCurrent(ele?.TDSAmount)}</td>
                          <td>
                            {coverAmountIntoCurrent(
                              ele?.Account - ele?.TDSAmount
                            )}
                          </td>
                          {/* <td>{ele?.RefNumber}</td> */}
                        </tr>
                      ))}
                    <tr>
                      <th scope="row"></th>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>

                      <td>Total:-</td>
                      <td>
                        {coverAmountIntoCurrent(
                          PaymentList.reduce((sum, a) => sum + a?.Account, 0)
                        )}
                      </td>
                      <td>
                        {coverAmountIntoCurrent(
                          PaymentList.reduce((sum, a) => sum + a?.TDSAmount, 0)
                        )}
                      </td>
                      <td>
                        {coverAmountIntoCurrent(
                          PaymentList.reduce(
                            (sum, a) => sum + (a?.Account - a?.TDSAmount),
                            0
                          )
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              }
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TDSReport;
