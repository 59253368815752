import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses, ToastWarn } from "../../Utils/Tost";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../config";
import { AiFillDelete } from "react-icons/ai";
import Footer from "../Footer/Footer";
import { BiEdit } from "react-icons/bi";
import Header from "../Header/Header";
import Select from "react-select";
const CreateReceipt = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const _id = queryParams.get("_id");
  const Mode = Boolean(queryParams.get("Mode"));

  const MASTER_DB = ["CASH", "BANK"];
  const MASTER_DB2 = ["LedgerGroup", "POS", "Employee"];
  const today = new Date();
  const lastDay = today.toISOString().substr(0, 10);

  const [EntryDate, setEntryDate] = useState(lastDay);

  const [CashBankAccount, setCashBankAccount] = useState({
    _id: "",
    Name: "-- Select --",
  });

  const [Remark, setRemark] = useState("");
  const [From, setFrom] = useState();
  const [FromModal, setFromModal] = useState("");

  const [PartyAccountOptFor, setPartyAccountOptFor] = useState([]);
  const [PartyAccount, setPartyAccount] = useState({
    _id: "",
    Name: "-- Select --",
  });
  const [PartyAccountOpt, setPartyAccountOpt] = useState();
  const [Account, setAccount] = useState("");
  const [RefNumber, setRefNumber] = useState("");

  const [Receipt, setReceipt] = useState([]);
  const navigate = useNavigate();

  const handleCreate = async () => {
    try {
      if (RefNumber || Account || From) {
        return ToastWarn("Add to List First");
      }

      if (!FromModal) {
        return ToastError("Ledger Type is required");
      }

      const que = {};

      if (FromModal === "BANK") {
        if (!CashBankAccount._id) {
          return ToastError("BANK Account is required");
        }
        que["CashBankAccount"] = CashBankAccount._id;
      } else if (FromModal === "CASH") {
        if (!CashBankAccount._id) {
          return ToastError("Cash Account is required");
        }
        que["LedgerEntry"] = CashBankAccount._id;
      }

      if (Receipt.length === 0) {
        return ToastError(
          "Please select Receipt Account for create this ledger"
        );
      }

      const {
        data: { success, message },
      } = await axiosInstance.post("/Receipt", {
        ...que,
        EntryDate,
        Remark,
        Receipt,
        FromModal,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/vouchers/Receipt/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const handleEdit = async () => {
    const que = {};

    if (FromModal === "BANK") {
      if (!CashBankAccount._id) {
        return ToastError("BANK is required");
      }
      que["CashBankAccount"] = CashBankAccount._id;
    } else if (FromModal === "CASH") {
      if (!CashBankAccount?._id) {
        return ToastError("LedgerGroup is required");
      }
      que["LedgerEntry"] = CashBankAccount._id;
    }
    if (RefNumber || Account || From) {
      return ToastWarn("Add to List First");
    }

    try {
      const {
        data: { success, message },
      } = await axiosInstance.put(`/Receipt/${_id}`, {
        ...que,
        EntryDate,
        Remark,
        Receipt,
        FromModal,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/vouchers/Receipt/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const removeHandler = (index) => {
    const ReceiptArr = Receipt;
    ReceiptArr.splice(index, 1);
    setReceipt([...ReceiptArr]);
  };

  const editHandler = (data, index) => {
    if (Account || RefNumber || Account) {
      return ToastWarn("Please Add To List First");
    }

    if (data.From === "POS") {
      if (data?.PartyAccount) {
        setPartyAccount({ _id: data?.PartyAccount, Name: data?.Name });
      } else {
        setPartyAccount({ _id: data?._id, Name: data?.Name });
      }
      //  LedgerGroup
    }
    if (data.From === "Employee") {
      if (data?.Employee) {
        setPartyAccount({ _id: data?.Employee, Name: data?.Name });
      } else {
        setPartyAccount({ _id: data?._id, Name: data?.Name });
      }
      //  LedgerGroup
    }
    if (data.From === "Broker") {
      if (data?.Broker) {
        setPartyAccount({ _id: data?.Broker, Name: data?.Name });
      } else {
        setPartyAccount({ _id: data?._id, Name: data?.Name });
      }
    }
    if (data.From === "LedgerGroup") {
      if (data?.LedgerEntry) {
        setPartyAccount({ _id: data?.LedgerEntry, Name: data?.Name });
      } else {
        setPartyAccount({ _id: data?._id, Name: data?.Name });
      }
      //  LedgerGroup
    }

    getPos(data.From);
    setAccount(data.Account);
    setRefNumber(data.RefNumber);
    setFrom(data.From);
    const ReceiptArr = Receipt;
    ReceiptArr.splice(index, 1);
    setReceipt([...ReceiptArr]);
  };
  const addHandler = () => {
    if (!PartyAccount || !Account) {
      return ToastWarn("Please enter a valid field");
    }

    const que = {};
    const PartyAccountData = PartyAccount;

    if (From === "POS") {
      que["PartyAccount"] = PartyAccountData._id;
    } else if (From === "LedgerGroup") {
      que["LedgerEntry"] = PartyAccountData._id;
    } else if (From === "Broker") {
      que["Broker"] = PartyAccountData._id;
    } else if (From === "Employee") {
      que["Employee"] = PartyAccountData._id;
    }
    // if (From === "POS") {
    //   que["PartyAccount"] = PartyAccountData._id;
    // }
    // if (From === "LedgerGroup") {
    //   que["LedgerEntry"] = PartyAccountData._id;
    // }

    if (!PartyAccountData?._id) {
      return ToastWarn("Please enter a Party Account");
    }
    setReceipt([
      ...Receipt,
      {
        ...que,
        Account: Number(Account),
        RefNumber,
        Name: PartyAccountData.Name,
        From: From,
      },
    ]);
    setPartyAccount({
      _id: "",
      Name: "-- Select --",
    });
    setAccount("");
    setFrom("");
    setRefNumber("");
  };

  const getPos = async (isData) => {
    try {
      if (isData === "Broker") {
        const {
          data: { data: Broker },
        } = await axiosInstance.get("/Broker");

        const LedgerGroup = Broker.map(({ Name, _id }) => {
          return {
            Name,
            _id,
          };
        });

        setPartyAccountOpt(LedgerGroup);
      }
      if (isData === "POS") {
        const {
          data: { data: posDetails },
        } = await axiosInstance.post("/Pos/getPosByField", {
          showFiled: {
            Name: 1,
            Salutation: 1,
            MiddleName: 1,
            LastName: 1,
            _id: 1,
          },
        });

        const POS = posDetails.map(
          ({
            Name = "",
            Salutation = "",
            MiddleName = "",
            LastName = "",
            _id,
          }) => {
            return {
              Name: ` ${Salutation} ${Name} ${MiddleName} ${LastName}`,
              _id,
            };
          }
        );
        setPartyAccountOpt([
          {
            _id: "",
            Name: "-- Select --",
          },
          ...POS,
        ]);
      }
      if (isData === "Employee") {
        const {
          data: { data: EmployeeDetails },
        } = await axiosInstance.get("/Employee/selectBoxDropdown");

        const EMP = EmployeeDetails.map(
          ({
            Name = "",
            Salutation = "",
            MiddleName = "",
            LastName = "",
            _id,
          }) => {
            return {
              Name: ` ${Salutation} ${Name} ${MiddleName} ${LastName}`,
              _id,
            };
          }
        );
        setPartyAccountOpt([
          {
            _id: "",
            Name: "-- Select --",
          },
          ...EMP,
        ]);
      }

      if (isData === "LedgerGroup") {
        const {
          data: { data: LedgerGroupData },
        } = await axiosInstance.get("/LedgerEntry");

        const LedgerGroup = LedgerGroupData.map(({ Name, _id }) => {
          return {
            Name,
            _id,
          };
        });

        setPartyAccountOpt(LedgerGroup);
      }
    } catch (error) {}
  };
  const getDataById = async () => {
    try {
      const {
        data: { data: value = {} },
      } = await axiosInstance.get(`/Receipt/single/${_id}`);

      getPosPartyAccount(value?.FromModal);
      setFromModal(value?.FromModal);
      setRemark(value?.Remark);
      convertReceipt(value?.Receipt);
      setEntryDate(value?.EntryDate);
      setCashBankAccountHandler(value);
      if (value?.FromModal === "CASH") {
        setCashBankAccount(value?.LedgerEntry);
      } else {
        setCashBankAccount(value?.CashBankAccount);
      }
    } catch (error) {}
  };

  const convertReceipt = (Receipt = []) => {
    const myArr = Receipt?.map((ele) => {
      const {
        LedgerEntry: Entry,
        PartyAccount: Account,
        Broker: BrokerDetails,
        Employee: EmployeeDetails,
        _id,
        ...rest
      } = ele;

      let obj;
      if (Entry) {
        const { _id: LedgerEntry, Name } = Entry;
        obj = {
          ...rest,
          LedgerEntry,
          Name,
        };
      }
      if (BrokerDetails) {
        const { _id: Broker, Name } = BrokerDetails;
        obj = {
          ...rest,
          Broker,
          Name,
        };
      }
      if (Account) {
        const { _id: PartyAccount, Name } = Account;
        obj = {
          ...rest,
          PartyAccount,
          Name,
        };
      }
      if (EmployeeDetails) {
        const { _id: Employee, Name } = EmployeeDetails;
        obj = {
          ...rest,
          Employee,
          Name,
        };
      }
      return obj;
    });

    setReceipt(myArr);
  };

  const getPosPartyAccount = async (isData) => {
    try {
      if (isData === "BANK") {
        const {
          data: { data },
        } = await axiosInstance.get("/Bank");

        // setCashBankAccountOpt([...data, ...LedgerGroup]);
        setPartyAccountOptFor(data);
      }

      if (isData === "CASH") {
        const {
          data: { data: LedgerGroupData },
        } = await axiosInstance.get("/LedgerEntry/getCashAccount");

        const LedgerGroup = LedgerGroupData.map(({ Name, _id }) => {
          return {
            Name,
            _id,
          };
        });

        setPartyAccountOptFor(LedgerGroup);
      }
    } catch (error) {}
  };

  const setCashBankAccountHandler = (value) => {
    if (!value) {
      return;
    }
    const { LedgerEntry: Entry, CashBankAccount: Bank } = value;
    let obj;
    if (Entry) {
      const { _id, Name } = Entry;
      obj = {
        _id,
        Name,
      };
    }
    if (Bank) {
      const { _id, Name } = Bank;
      obj = {
        _id,
        Name,
      };
    }

    setCashBankAccount(obj);
  };

  useEffect(() => {
    if (_id) {
      getDataById();
    }
  }, [_id]);
  return (
    <>
      <Header />

      <div className="container-fluid" style={{ marginTop: "70px" }}>
        <div className="row mx-2 ">
          <div
            className="card-body CreateCard"
            style={{
              height: "30vh",
              zoom: "90%",
            }}
          >
            <h5>{Mode ? "Edit Receipt" : "Create Receipt"}</h5>
            <hr />
            <br />
            <div className="row mx-2 ">
              <div className="col-md-2 align-items-center">
                <label>EntryDate</label>
                <input
                  className="form-control "
                  type="date"
                  name="EntryDate"
                  value={EntryDate}
                  onChange={(e) => setEntryDate(e.target.value)}
                  placeholder="Enter Receipt "
                  required //done
                />
              </div>
              <div className="col-md-2 align-items-center">
                <label key="lev1"></label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  name="PolicyType"
                  value={FromModal}
                  onChange={(e) => {
                    setFromModal(e.target.value);
                    getPosPartyAccount(e.target.value);
                    setCashBankAccount({
                      _id: "",
                      Name: "-- Select --",
                    });
                  }}
                  required
                >
                  <option value=""> Select Ledger</option>

                  {MASTER_DB.map((ele) => {
                    return <option value={ele}>{ele}</option>;
                  })}
                </select>
              </div>
              <div className="col-md-3 align-items-center">
                <label>CashBankAccount</label>
                <Select
                  style={{ backgroundColor: "#F5F5F6" }}
                  className="basic-single"
                  classNamePrefix="select"
                  options={PartyAccountOptFor}
                  // defaultValue={{
                  //   _id:"-- Please Select Variant --"
                  // }}
                  getOptionValue={(option) => option["_id"]}
                  getOptionLabel={(option) => option["Name"]}
                  onChange={setCashBankAccount}
                  value={CashBankAccount}
                />
                {/* <select
                  
                  class="form-select"
                  aria-label="Default select example"
                  name="CashBankAccount"
                  value={CashBankAccount}
                  onChange={(e) => setCashBankAccount(e.target.value)}
                  required
                >
                  <option value="">
                    -------------Select Bank-------------
                  </option>
                  {CashBankAccountOpt &&
                    CashBankAccountOpt.map((data) => (
                      <option value={data._id}>{data.Name}</option>
                    ))}
                 
                </select> */}
              </div>
              <div className="col-md-3 align-items-center">
                <label>Remark</label>
                <input
                  className="form-control "
                  type="text"
                  name="Remark"
                  value={Remark}
                  onChange={(e) => setRemark(e.target.value)}
                  placeholder="Remark"
                  required //done
                />
              </div>
            </div>
          </div>
          <div className="card-body CreateCard" style={{ zoom: "90%" }}>
            <div className="row mx-2 ">
              <div className="col-md-3 align-items-center">
                <label></label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  name="PolicyType"
                  value={From}
                  onChange={(e) => {
                    setFrom(e.target.value);
                    getPos(e.target.value);
                    setPartyAccount({ _id: "", Name: "-- Select --" });
                  }}
                  required
                >
                  <option value=""> Select Ledger</option>

                  {MASTER_DB2.map((ele) => {
                    return <option value={ele}>{ele}</option>;
                  })}
                </select>
              </div>
              <div className="col-md-3 align-items-center">
                <label>Party Account</label>
                <Select
                  style={{ backgroundColor: "#F5F5F6" }}
                  className="basic-single"
                  classNamePrefix="select"
                  options={PartyAccountOpt}
                  // defaultValue={{
                  //   _id:"-- Please Select Variant --"
                  // }}
                  getOptionValue={(option) => option["_id"]}
                  getOptionLabel={(option) => option["Name"]}
                  onChange={setPartyAccount}
                  value={PartyAccount}
                />
                {/* <select
                  
                  class="form-select"
                  aria-label="Default select example"
                  name="PartyAccount"
                  value={PartyAccount}
                  onChange={(e) => {
                    setPartyAccount(e.target.value);
                  }}
                  required
                >
                  <option value="">-------------Select -------------</option>
                  {PartyAccountOpt &&
                    PartyAccountOpt.map((data) => (
                      <option value={JSON.stringify(data)}>{data.Name}</option>
                    ))}
                </select> */}
              </div>
              <div className="col-md-3 align-items-center">
                <label>Account</label>
                <input
                  className="form-control "
                  type="number"
                  name="Account"
                  value={Account}
                  onChange={(e) => setAccount(e.target.value)}
                  placeholder="Amount"
                  required //done
                />
              </div>
              <div className="col-md-3 align-items-center">
                <label>Narration</label>
                <input
                  className="form-control "
                  type="text"
                  name="Ref Numbe"
                  value={RefNumber}
                  onChange={(e) => setRefNumber(e.target.value)}
                  placeholder="Enter Narration"
                  required //done
                />
              </div>
              {
                <div
                  className="col-md-3 align-items-center"
                  style={{
                    paddingTop: "1.4rem",
                  }}
                >
                  <button
                    className="btn mybtn"
                    onClick={() => {
                      addHandler();
                    }}
                  >
                    Add to list
                  </button>
                </div>
              }
              <div
                className="col-md-12 align-items-center"
                style={{ marginTop: "20px" }}
              >
                {
                  <table class="table table-striped table-bordered overflow-x mt-3">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">PartyAccount</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Narration</th>
                        {<th scope="col">Action</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {Receipt.map((ele, index) => (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{ele?.Name}</td>
                          <td>{ele?.Account}</td>
                          <td>{ele?.RefNumber}</td>
                          {
                            <td>
                              <BiEdit
                                size={32}
                                style={{
                                  backgroundColor: "green",
                                  color: "#fff",
                                  padding: "5px",
                                  borderRadius: "5px",
                                  marginTop: "-5px",
                                }}
                                onClick={() => editHandler(ele, index)}
                              />
                              &nbsp;
                              <AiFillDelete
                                size={32}
                                style={{
                                  backgroundColor: "#A50406",
                                  color: "#fff",
                                  padding: "5px",
                                  borderRadius: "5px",
                                  marginTop: "-5px",
                                }}
                                onClick={() => {
                                  const isTrue = window.confirm(
                                    "Do you want to delete!"
                                  );
                                  if (isTrue) {
                                    removeHandler(index);
                                  }
                                }}
                              />
                            </td>
                          }
                        </tr>
                      ))}
                    </tbody>
                  </table>
                }
              </div>
            </div>
          </div>

          <div className="col-lg-5" style={{ float: "left" }}></div>
          <div className="col-lg-2" style={{ float: "left" }}>
            <center>
              <button
                className="btn mybtn "
                onClick={() => {
                  Mode ? handleEdit() : handleCreate();
                }}
              >
                Submit
              </button>
            </center>
          </div>
          <div className="col-lg-5" style={{ float: "left" }}></div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default CreateReceipt;
