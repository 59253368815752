const headers = [
  "Insurance Company",
  "Product",
  "Sub Product",
  "Vehicle Class",
  "GVW From",
  "GVW To",
  "Seating From",
  "Seating To",
  "Policy Type",
  "Fuel Type",
  "RTO Code",
  "Cluster Name",
  "Date (From)",
  "Date (To)",
];

const headersPos = [
  "Code",
  "Branch",
  "Name",
  "Alias Name",
  "Mobile",
  "Email",
  "Opening Balance",
  "Aadhaar Number",
  "Bank Name",
  "IFSC",
  "Marital Status",
  "GSTIN",
  "Pan Number",
  "Address",
  "Parent Pos",
  "Reporting To Motor",
  "Bank Account No",
  "Examined",
  "Examined Status",
  "Examined Remark",
  "City",
  "Pin Code",
  "Reporting To Non Motor",

  "Club",
];
const headersBulkPos = [
  "Code",
  "Branch",
  "Name",
  "Alias Name",
  "Mobile",
  "Email",
  "Opening Balance",
  "Aadhaar Number",
  "Bank Name",
  "IFSC",
  "Marital Status",
  "GSTIN",
  "Pan Number",
  "Address",
  "Parent Pos",
  "Reporting To Motor",
  "Bank Account No",
  "Examined",
  "Examined Status",
  "Examined Remark",
  "City",
  "Pin Code",
  "Reporting To Non Motor",
  "Club",
];

const headersEmployee = [
  "Name",
  "Middle Name",
  "Last Name",
  "Reporting To",
  "Mobile Number",
  "Email",
  "DOA",
  "DOB",
  "Marital Status",
  "Father Name",
  "Gender",
  "Aadhaar Number",
  "Joining Date",
  "Pan Number",
  "Department",
  "Digination",
  "Spoke Location",
  "Referral By",
  "Nominee Name",
  "Nominee Relation",
  "Branch",
  "PF Number",
  "Employee State Insurance",
  "Permission",
  "Address",
  "State",
  "City",
  "Pin Code",
  "Emergency Contact Number",
  "Weekend Day",
  "Qualification",
  "Religion",
  "Blood Group",
  "Account Holder Name",
  "Bank Name",
  "IFSC",
  "Bank Account No",
];

const headersPayout = [
  "SNo.",
  "Insurance Company",
  "Product",
  "Sub Product",
  "Vehicle Class",
  "Policy Type",
  "Branch",
  "CC From",
  "CC To",
  "PAPremium From",
  "PAPremium To",
  "GVW From",
  "GVW To",
  "Seater From",
  "Seater To",
  "LOB",
  "RTO Cluster",
  "Make",
  "Model",
  "Variant",
  "Fuel Type",
  "PA",
  "Customer Type",
  "Based On",
  "NCB Status",
  "ODD (From)",
  "ODD (To)",
  "Business Type",
  "DEF (From)",
  "DET (To)",
  "From Age",
  "To Age",
  "Reciveable",
  "Nil Dep",
  "IMT Type",
  "Policy Paying Term",
  "Inflow (Spl. Apvl)",
  "Insurer Branch",
];

export const validateAndUpdateField = (
  dataArray,
  keyName,
  fieldName,
  mapping
) => {
  const errorPolicyData = [];
  const data = dataArray.map((item) => {
    const mappedId = mapping[item[keyName]?.trim().toLowerCase()];

    if (!mappedId && item[keyName]) {
      errorPolicyData.push({
        valid: false,
        id: `${item?.["RTO Code"]}${item?.["Cluster Name"]}`,
        fieldName: keyName,
        fieldValue: item[keyName],
        row: { ...item, errMsg: `${keyName} not found!` },
      });
    }
    return {
      ...item,
      [fieldName]: mappedId,
    };
  });

  return { data, error: errorPolicyData };
};

export const correctedValidateAndUpdateField = (
  dataArray,
  keyName,
  fieldName,
  mapping
) => {
  const errorPolicyData = [];

  // Ensure mapping is an object
  if (typeof mapping !== "object" || mapping === null) {
    console.error("Invalid mapping provided:", mapping);
    return { data: dataArray, error: errorPolicyData };
  }

  const data = dataArray.map((item) => {
    // Safely handle keyName
    const keyValue = item[keyName];

    // Check if keyValue is a string, otherwise handle gracefully
    const normalizedKeyValue =
      typeof keyValue === "string" ? keyValue.trim().toLowerCase() : "";

    const mappedId = mapping[normalizedKeyValue];

    if (!mappedId && normalizedKeyValue) {
      errorPolicyData.push({
        valid: false,
        id: `${item?.["RTO Code"]}${item?.["Cluster Name"]}`,
        fieldName: keyName,
        fieldValue: keyValue,
        row: item,
      });
    }

    return {
      ...item,
      [fieldName]: mappedId,
    };
  });

  return { data, error: errorPolicyData };
};

export const validateHeadersExcel = (responseHeaders) => {
  const missingHeaders = [];

  // Check for missing or mismatched required headers
  headers.forEach((header, index) => {
    if (!responseHeaders.includes(header)) {
      missingHeaders.push(header);
    }
  });

  if (missingHeaders.length > 0) {
    return {
      valid: false,
      message: "Some required headers are missing!",
      missingHeaders,
    };
  }

  return {
    valid: true,
    message: "Headers match exactly!",
  };
};

export const validateHeadersPosExcel = (responseHeaders) => {
  const missingHeaders = [];

  // Check for missing or mismatched required headersPos
  headersPos.forEach((header, index) => {
    if (!responseHeaders.includes(header)) {
      missingHeaders.push(header);
    }
  });

  if (missingHeaders.length > 0) {
    return {
      valid: false,
      message: "Some required headers are missing!",
      missingHeaders,
    };
  }

  return {
    valid: true,
    message: "Headers match exactly!",
  };
};

export const validateHeadersBulkPosExcel = (responseHeaders, codes) => {
  const missingHeaders = [];

  headersBulkPos.forEach((header, index) => {
    if (!responseHeaders.includes(header)) {
      missingHeaders.push(header);
    }
  });

  const duplicateCodes = findDuplicates(codes);

  if (duplicateCodes.length > 0) {
    return {
      valid: false,
      message: "Duplicate Codes found!",
      duplicateCodes,
    };
  }

  if (missingHeaders.length > 0) {
    return {
      valid: false,
      message: "Some required headers are missing!",
      missingHeaders,
    };
  }

  return {
    valid: true,
    message: "Headers match exactly!",
  };
};

const findDuplicates = (array) => {
  const seen = new Set();
  const duplicates = [];

  array.forEach((item) => {
    if (seen.has(item)) {
      duplicates.push(item);
    } else {
      seen.add(item);
    }
  });

  return duplicates;
};

export const validateHeadersEmployeeExcel = (responseHeaders) => {
  const missingHeaders = [];

  // Check for missing or mismatched required headersPos
  headersEmployee.forEach((header, index) => {
    if (!responseHeaders.includes(header)) {
      missingHeaders.push(header);
    }
  });

  if (missingHeaders.length > 0) {
    return {
      valid: false,
      message: "Some required headers are missing!",
      missingHeaders,
    };
  }

  return {
    valid: true,
    message: "Headers match exactly!",
  };
};

export const validateHeadersExcelPayout = (responseHeaders) => {
  const missingHeaders = [];
  headersPayout.forEach((header, index) => {
    if (!responseHeaders.includes(header)) {
      missingHeaders.push(header);
    }
  });

  if (missingHeaders.length > 0) {
    return {
      valid: false,
      message: "Some required headers are missing!",
      missingHeaders,
    };
  }

  return {
    valid: true,
    message: "Headers match exactly!",
  };
};

export const updateInsuranceType = (mapData, data) => {
  const errorPolicyData = [];

  const updateData = data.map((item) => {
    const keyName =
      item?.["Vehicle Class"] ||
      item?.["Sub Product"] ||
      item?.["Product"] ||
      null;

    //   const mappedData = mapData[keyName?.trim()];
    const mappedData = mapData[keyName?.trim?.().toLowerCase()];

    if (!mappedData && item?.["Product"]) {
      errorPolicyData.push({
        valid: false,
        id: `${item?.["RTO Code"]}${item?.["Cluster Name"]}${item?.["Product"]}`,
        fieldName: "Product",
        fieldValue: item?.["Product"],
        row: { ...item, errMsg: "Product not found" },
      });
    }
    if (!mappedData && item?.["Sub Product"]) {
      errorPolicyData.push({
        valid: false,
        id: `${item?.["RTO Code"]}${item?.["Cluster Name"]}${item?.["Sub Product"]}`,
        fieldName: "Sub Product",
        fieldValue: item?.["Sub Product"],
        row: { ...item, errMsg: "Sub Product not found" },
      });
    }

    if (!mappedData && item?.["Vehicle Class"]) {
      errorPolicyData.push({
        valid: false,
        id: `${item?.["RTO Code"]}${item?.["Cluster Name"]}${item?.["Vehicle Class"]}`,
        fieldName: "Vehicle Class",
        fieldValue: item?.["Vehicle Class"],
        row: { ...item, errMsg: "Vehicle Class not found" },
      });
    }

    return { ...item, InsuranceType: mappedData || null };
  });
  return { data: updateData, error: errorPolicyData };
};

export const updatePayoutInsuranceType = (mapData, data, mapWithObj) => {
  const errorPolicyData = [];
  const updateData = data.map((item) => {
    const keyName =
      item?.["Vehicle Class"] ||
      item?.["Sub Product"] ||
      item?.["Product"] ||
      null;

    //   const mappedData = mapData[keyName?.trim()];
    const mappedData = mapData[keyName?.trim?.().toLowerCase()];

    if (!mappedData && item?.["Product"]) {
      errorPolicyData.push({
        valid: false,
        id: `${item?.["SNo."]}`,
        fieldName: "Product",
        fieldValue: item?.["Product"],
        row: item,
      });
    }
    if (!mappedData && item?.["Sub Product"]) {
      errorPolicyData.push({
        valid: false,
        id: `${item?.["SNo."]}`,
        fieldName: "Sub Product",
        fieldValue: item?.["Sub Product"],
        row: item,
      });
    }

    if (!mappedData && item?.["Vehicle Class"]) {
      errorPolicyData.push({
        valid: false,
        id: `${item?.["SNo."]}`,
        fieldName: "Vehicle Class",
        fieldValue: item?.["Vehicle Class"],
        row: item,
      });
    }

    let insUnderflow = [];

    if (mappedData) {
      const insType = mapWithObj[mappedData];
      insUnderflow = [...insType?.InsuranceUnderFlow];
      insUnderflow.push(insType?._id);
    }

    return {
      ...item,
      InsuranceType: mappedData || null,
      InsuranceUnderFlow: insUnderflow,
    };
  });
  return { data: updateData, error: errorPolicyData };
};

export const updateRTOCluster = (rtoCluster, data) => {
  const errorPolicyData = [];

  const updateData = data.map((item) => {
    const clusterName = (item?.["RTO Cluster"] || "").toLowerCase();
    const insCompany = (item?.InsuranceCompany || "").trim();
    const InsType = (item?.InsuranceType || "").trim();
    const InsUnderflow = item?.InsuranceUnderFlow || [];
    const policyType = (item?.PolicyType || "").trim();
    const fuelType = (item?.FuelType || "").trim();
    const DEF = item?.["DEF (From)"];
    const DET = item?.["DET (To)"];
    const seaterFrom = item?.["Seater From"] || "";
    const seaterTo = item?.["Seater To"] || "";
    const GVWTo = item?.["GVW To"] || "";
    const GVWFrom = item?.["GVW From"] || "";

    let filterByGroupName = rtoCluster;

    // if (item["SNo."] === 36) {
    //   console.log("clusterName", clusterName);
    // }

    if (clusterName) {
      filterByGroupName = rtoCluster.filter(
        (item) => item?.GroupName?.toLowerCase() === clusterName.trim()
      );
    }

    // if (item["SNo."] === 1) {
    //   console.log("filterByGroupName", filterByGroupName);
    // }

    const filterInsCompany = filterByGroupName.filter((item) => {
      const defDate = new Date(DEF);
      const detDate = new Date(DET);

      const clusterDefDate = new Date(item?.DEF);
      const clusterDetDate = new Date(item?.DET);

      const isIssueDateInRange =
        defDate >= clusterDefDate && detDate <= clusterDetDate;

      const isCompanyMatch = item?.InsuranceCompany === insCompany;

      return isCompanyMatch && isIssueDateInRange;
    });

    let filterInsType = filterInsCompany;

    // if (item["SNo."] === 1) {
    //   console.log("filterInsCompany", filterInsCompany);
    // }
    if (InsType) {
      const exactMatch = filterInsCompany.filter((item) => {
        return item?.InsuranceType && InsType
          ? item?.InsuranceType === InsType
          : false;
      });

      const underflowMatch = filterInsCompany.filter((item) => {
        return item?.InsuranceType
          ? InsUnderflow.includes(item?.InsuranceType)
          : true;
      });

      let semiExactMatch = [];
      let allMatch = [];

      if (
        exactMatch.length === 0 &&
        underflowMatch &&
        underflowMatch.length > 0
      ) {
        underflowMatch.forEach((item) => {
          if (item?.InsuranceType) {
            semiExactMatch.push(item);
          } else {
            allMatch.push(item);
          }
        });
      }

      filterInsType =
        exactMatch.length > 0
          ? exactMatch
          : semiExactMatch?.length > 0
          ? semiExactMatch
          : allMatch;
    }

    let filterPolicyType = filterInsType;
    // if (item["SNo."] === 1) {
    //   console.log("filterInsType", filterInsType);
    // }

    if (policyType) {
      filterPolicyType = filterPolicyType.filter((item) => {
        return item?.PolicyType ? item?.PolicyType === policyType : true;
      });
    }

    let filterFuelType = filterPolicyType;

    if (fuelType) {
      filterFuelType = filterFuelType.filter((item) => {
        return item?.FuelType ? item?.FuelType === fuelType : true;
      });
    }

    let filterSeaterFrom = filterFuelType;

    // if (item["SNo."] === 1) {
    //   console.log("filterPolicyType", filterPolicyType);
    // }

    if (seaterFrom) {
      filterSeaterFrom = filterPolicyType.filter((item) =>
        item?.seaterFrom && seaterFrom
          ? seaterFrom >= item?.seaterFrom && seaterFrom <= item?.seaterTo
          : true
      );
    }

    let filterSeaterTo = filterSeaterFrom;

    // if (item["SNo."] === 1) {
    //   console.log("seaterTo", seaterTo);
    //   console.log("filterSeaterFrom", filterSeaterFrom);
    // }

    if (seaterTo) {
      filterSeaterTo = filterSeaterFrom.filter((item) =>
        item?.seaterTo && seaterTo
          ? seaterTo >= item?.seaterFrom && seaterTo <= item?.seaterTo
          : true
      );
    }

    let filterGVWFrom = filterSeaterTo;

    // if (item["SNo."] === 1) {
    //   console.log("filterSeaterTo", filterSeaterTo);
    // }

    if (GVWFrom) {
      filterGVWFrom = filterSeaterTo.filter((item) =>
        item?.GVWFrom && GVWFrom
          ? GVWFrom >= item?.GVWFrom && GVWFrom <= item?.GVWTo
          : true
      );
    }

    let filterGVWTo = filterGVWFrom;

    // if (item["SNo."] === 1) {
    //   console.log("filterGVWFrom", filterGVWFrom);
    // }

    if (GVWTo) {
      filterGVWTo = filterGVWFrom.filter((item) =>
        item?.GVWTo && GVWTo
          ? GVWTo >= item?.GVWFrom && GVWTo <= item?.GVWTo
          : true
      );
    }

    // if (item["SNo."] === 1) {
    //   console.log("filterGVWTo", filterGVWTo);
    //   console.log(
    //     "filterGVWTo",
    //     filterGVWTo?.length === 0 && item?.["RTO Cluster"]
    //   );
    // }

    if (item["SNo."] === 1) {
      console.log("filterGVWTo", filterGVWTo);
    }

    if (filterGVWTo?.length === 0 && item?.["RTO Cluster"]) {
      errorPolicyData.push({
        valid: false,
        id: item?.["SNo."],
        fieldName: "RTO Cluster",
        fieldValue: item?.["RTO Cluster"],
        row: { ...item, errMsg: "RTO Cluster not found1!" },
      });
    } else if (filterGVWTo?.length > 1 && item?.["RTO Cluster"]) {
      errorPolicyData.push({
        valid: false,
        id: item?.["SNo."],
        fieldName: "RTO Cluster",
        fieldValue: item?.["RTO Cluster"],
        row: {
          ...item,
          errMsg: `More than one cluster found!, ${filterGVWTo
            .map((item) => item?._id)
            .join(",")}`,
        },
      });
    }
    return {
      ...item,
      RTOGroup: filterGVWTo?.length === 1 ? filterGVWTo[0]?._id : null,
    };
  });
  return { data: updateData, error: errorPolicyData };
};

export const updateVariant = (mapData, data, mappingObj) => {
  const errorPolicyData = [];
  const { Make, Model } = mappingObj;
  const variantMap = mapData.reduce((acc, item) => {
    const variant = (item["VariantName"] || "").trim();
    const make = (item?.Make?.Make || "").trim();
    const model = (item?.Model?.Model || "").trim();

    const key = `${variant}${make}${model}`.toLowerCase();

    acc[key] = item;
    return acc;
  }, {});

  const updateData = data.map((item) => {
    let mappedData = null;

    const variantKey = `${(item?.Variant ?? "")?.trim()}${(
      item?.Make ?? ""
    )?.trim()}${(item?.Model ?? "")?.trim()}`.toLowerCase();

    mappedData = variantMap[variantKey];

    if (!mappedData && Object.keys(Make).length > 0) {
      const makeKey = `${(item?.Make ?? "")?.trim()}`.toLowerCase();
      const make = Make[makeKey];

      if (!make && item?.Make) {
        errorPolicyData.push({
          valid: false,
          id: `${item?.["SNo."]}`,
          fieldName: "Make",
          fieldValue: item?.Make,
          row: { ...item, errMsg: "Make not found!" },
        });
      }

      mappedData = {
        _id: null,
        Make: { _id: make },
        Model: null,
      };
    }

    if (Object.keys(Model).length > 0) {
      const modelKey = `${(item?.Model ?? "")?.trim()}`.toLowerCase();
      const model = Model[modelKey];

      mappedData = {
        ...mappedData,
        Model: { _id: model },
      };
    }

    if (mappedData && !mappedData?.Model && Object.keys(Model).length > 0) {
      const modelKey = `${(
        item?.Model?.toString() ?? ""
      )?.trim()}`.toLowerCase();

      const model = Model[modelKey];

      if (!model && item?.Model) {
        errorPolicyData.push({
          valid: false,
          id: `${item?.["SNo."]}`,
          fieldName: "Model",
          fieldValue: item?.Model,
          row: { ...item, errMsg: "Model not found!" },
        });
      }

      mappedData = {
        ...mappedData,
        Model: { _id: model },
      };
    }

    if (!mappedData && item["Variant"]) {
      errorPolicyData.push({
        valid: false,
        id: `${item?.["SNo."]}`,
        fieldName: "Variant",
        fieldValue: item?.Variant,
        row: { ...item, errMsg: "Variant not found!" },
      });
    }

    return {
      ...item,
      MakeModal: mappedData?.Make?._id || null,
      Model: mappedData?.Model?._id || null,
      Variant: mappedData?._id || null,
    };
  });

  return { data: updateData, error: errorPolicyData };
};
